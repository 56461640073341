@import '../../styles/base';
.quiz-result-header {
  background: $white;
  display: flex;
  width: 100%;
  padding:20px 10%;
  height: 80px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(242, 240, 232, 0.5);
  justify-content: space-between;
  align-items: center;
  .header-logo {
    position: relative;    
    cursor: pointer;
  }
   .header-links {
     display: inline-flex;
     align-items: center;  
   }
}

.header-links-mobile {
  display: none;
  float: right;
  margin-top: 15px;
  margin-right: 5px;
  a {
    margin-right: 25px;
    vertical-align: middle;
    img {
      width: 18px;
      height: 18px;
    }
  }
}
.retake-quiz {
  display: flex;
  justify-content: flex-end;
  margin:15px 10%;
  cursor: pointer;
  a {
    font-weight: 400;
    font-size: $font-size-16;
    color: $color-primary;
  }
}
.quiz-result-container {
  margin: 0;
  .profile-section {
    margin:5% 10%;
    @media (max-width:1024px){
      margin:5% 30px;
    }
  }
  .quiz-result-heading {
    font-size: $heading-3;
    line-height: 55px;
    text-align: center;
    color: $color-text-dark;
    font-weight: 400;
    margin-bottom: 30px;
    padding-top: 30px;
  }
  .profile-video {
    width: 100%;
    height: 500px;
  }
  .profile-data {
    width: 100%;
    margin-top: 75px;
    .left {
      margin-right: 3%;
    }
    .right {
      margin-left: 3%;
    }
    .profile {
      width: 47%;
      display: inline-block;
      vertical-align: top;
      .profile-heading {
        font-size: $h1-font-size;
        color: $color-text-dark;
        border-left: 2px solid #80b2a3;
        padding-left: 30px;
      }
      .profile-desc {
        margin-top: 40px;
        font-size: $font-size-16;
        line-height: 28px;
        color: $color-text-dark;
        white-space: pre-line;
        padding-left: 30px;
      }
    }
  }
.section-container {
    background-color: #f9f9f9;
  .section {
    padding:0% 10%;
    margin-bottom: 5px;
    @media (max-width:1024px){
      padding:0% 30px;
    }
    .heading {
      font-weight: 500;
      font-size: $h1-font-size;
      line-height: 32px;
      color: $color-text-dark;
      display: inline-block;
      margin-bottom: 3%;
      margin-top: 3%;
    }
    a {
      margin-left: 40px;
      font-weight: 500;
      font-size: $font-size-16;
      line-height: 24px;
      color: $color-primary;
      cursor: pointer;
    }
      .nav-menu-item{
        display:block;
        width: 100%;
        margin-bottom: 5px;
      }
      .nav-menu-item-body{
        overflow: hidden; 
        height: 0; 
        opacity: 0;
        // padding: 0% 8% 0% 5%;
        transition: height 0ms 400ms, opacity 400ms 0ms;
        background-color: #F9F9F9;
        .palette-div {
          text-align: center;
          margin-bottom: 25px;
          img {
            width: 60%;
          }
        }
      }
      .nav-menu-item-head-help{
        position: absolute;
        right: 35px;
      }
      .nav-menu-item-head{
         position: relative;
         width: 100%;
      }
      .nav-menu-item-head:after {
        content: url("../../assets/icon/down.png");
        font-size: 40px;
        color: #006747;
        float: right;
        margin-left: 5px;
      }
    .tile-section {
      margin: 0;
      .tile-container {
        display: inline-block;
        width: 100%;
        text-align: center;
        .tilestack {
          justify-content: center;
          display: flex;
          flex-direction: row;
          .tile {
            max-width: 255px;
            width: 25%;
            display: block;
            margin: 3%;
            background: $white;
            border-radius: 12px;
            transition: all 0.3s ease-in;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            cursor: pointer;
            &:hover {
              box-shadow: 0px 22px 24px 0px rgba(46, 51, 51, 0.07);
              transition: all 0.3s ease-out;
            }
            .tile-image-wrap {
              display: inline-block;
              align-items: center;
              justify-content: center;
              margin-top: 25%;
              .tileimage {
                display: inline-block;
                margin-bottom: 5%;
                width: 45px;
                height: 50.84px;
              }
            }
            .tile-text {
              left: 0px;
              font-size: $h2-font-size;
              line-height: 1.5;
              color: $color-primary;
              overflow-wrap: normal;
              padding: 5% 11% 16%;
            }
          }
        }
      }
    }
    .disclaimer {
      width: 100%;
      display:block;
      font-size:  $font-size-12;
      line-height: 30px;
      color: $color-text-dark;
      text-align: left;
      margin-bottom: 30px;
      margin-top: 30px;
      &.grossarea {
        padding-left: 40px;
        margin: 0;
        line-height: 1.4;
      }
      span {
        font-weight: 600;
        margin-right: 10px;
      }      
    }
  }
}
  .nav-menu-item.item-active .nav-menu-item-head:after {
    content: url("../../assets/icon/Up.png");
    font-size: 40px;
    color: $color-primary;
    float: right;
    margin-left: 5px;
  }
  .nav-menu-item.item-active .nav-menu-item-body{
    height: auto; 
    opacity: 1;
    transition: height 0ms 0ms, opacity 600ms 0ms;
  }
}
.show {
  display: flex !important;
}
.hide {display: none !important;}
.icon-success {
  background: url("../../assets/icon/success_badge.png") no-repeat center center;
  margin-right: 10px;
  position: relative;
  top: 1px;
  background-size: 100%;
@media screen and (max-width:500px) {
  background-size: 100%;
  height: 16px !important;
  width: 16px !important;  
}

}
.thumbnail_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  background-size: cover;
.play_icon {background: radial-gradient(rgba($black, 30%), rgba($black, 0) 60%);
border-radius: 64px;
width: 64px;
height: 64px;
display: flex;
align-items: center;
justify-content: center;
  a {
    font-size: 1px;
      border-style: solid;
      cursor: pointer;
        border-width: 16px 0px 16px 26px;
        border-color: transparent transparent transparent white;
        margin-left: 7px;
        display: inline-flex;
      }
  }
}
#nextStep {
  .tile{
    width: 100% !important;
  }
  .next_step_box{
    display: flex;
    justify-content: space-between;
    p{
    font-size: $font-size-16;
    line-height: 28px;
    color: $color-text-dark;
    }
    >div:first-child{
      width: calc(90% - 325px);
    }
    .tile-section{
      width: 25%;
      margin-left: 20px;
      margin-right: 50px;
      margin-bottom: 20px;
      max-width: 255px;
    }
  }
}
.iframe_container {
    p { 
        font-size:  $font-size-12;
        line-height: 1.5;
        color: $color-text-dark;
    }
}
.m-l-8{
  margin-left: 8% !important;
}
.cbre_home_logo{
  text-decoration: none;
}
@media screen and (max-width: 540px) {
  .nav-menu-item-body{
    .row-profile{
      width: 100%;
      .box-bg-style{
        width: calc(100% - 10px);
      }
    }
  }
  .quiz-result-header {    
    .header-logo {
      margin-top: 8%;
      margin-bottom: 5%;
     
    }
  }
    
  .quiz-result-container {
    margin: 0;
    width: 100%;
    margin-bottom: 10px;
    .profile-video {
      width: 100%;
      height: 147px;
    }
    .quiz-result-heading {
      font-size: $h2-font-size;
      line-height: 30px;
      padding-top: 0px;
      span
      {
        display: block;
      }
    }
    .profile-data {
      width: 100%;
      margin-top: 25px;
      .left {
        margin-right: 0%;
      }
      .right {
        margin-left: 0%;
      }
      .profile {
        width: 100%;
        display: block;
        .profile-heading {
          margin-top: 25px;
          font-size: $font-size-16;
          line-height: 18px;
          padding-left: 15px;
        }
        .profile-desc {
          font-size: $font-size-14;
          margin-top: 9px;
          text-align: justify;
          line-height: 22px;
          padding-left: 15px;
          .mtop20 {
            margin: 0px;
          }
        }
      }
    }
    .section-container {
    .section {
      .nav-menu-item-body{
        .palette-div {
          img {
            width: 100%;
          }
        }
      }
      .nav-menu-item-head {
        padding: 0px;
        width: 100%;
      }
      .heading{
        font-size: $h3-font-size;
        line-height: 20px;
        margin-bottom: 7%;
        margin-top: 7%;
      }
      .nav-menu-item-head:after {
        font-size: $h2-font-size;
        margin-left: 5px;

      }
      .disclaimer {
        font-size: $font-size-10;
        font-weight: normal;
        line-height: 20px;
        margin-top: 0px;
        margin-bottom: 10px;
      }
      .tile-section {
        .tile-container {
          display: inline-block;
          width: 100%;
          .tilestack {
            display: flex;
            padding-bottom: 15px;
            .tile {
              display: block;
              width: calc(100vw / 3);
              border-radius: 12px;                
              transition: all 0.3s ease-in;
              border-bottom: 1px solid #D7D7D7;
              background: $white;
              .tile-image-wrap {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  margin-top: 25%;
                  .tileimage {
                    display: inline-block;
                    width: 40px;
                    height: auto;
                    border-radius: 12px;
                  }
                }
                .tile-text {
                font-size:  $font-size-12;
                line-height: 1.5;
                text-align: center;
                padding: 12% 5%
                }
              &:hover {
                box-shadow: 0px 22px 24px 0px rgba(46, 51, 51, 0.07);
                transition: all 0.3s ease-out;
                .tile-image-wrap {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-end;
                  justify-content: center;
                  .tileimage {
                    display: inline-block;
                    margin-bottom: 20%;
                    margin-top: 30%;
                    border-radius: 8px;
                  }
                }
                .tile-text {
                  font-size: 1em;
                  line-height: 1.6;
                  overflow-wrap: normal;
                }
              }
            }
          }
        }
      }
    }
    .nav-menu-item{
      margin-bottom: 5px;
    }
    .nav-menu-item.item-active .nav-menu-item-head:after { 
      font-size: 20px;
      margin-left: 5px;
    }
  }
  }
  .thumbnail_container{
    height: 350px;
  }
  .iframe_container {
    iframe{
    height: 350px !important;
  }
  p{
    font-size:  $font-size-12;
    margin-top: 9px;
    text-align: justify;
    line-height: 1.4;
  }
  }
  #nextStep {
    .next_step_box{
      flex-direction: column;
      align-items: center;
      p{
        font-size: $font-size-14;
        line-height: 20px;
      }
      >div:first-child{
        width: 100%;
      }
      .tile-section {
        width: 40%;
        margin-left: 0;
        margin-right: 0;
        .tileimage{
          border-radius: 0 !important;
        }
      }
    }
  }
}
@media screen and (max-width:767px){
  .header-links-mobile {
    display: block;
    float: right;
    margin-top: 15px;
    margin-right: 5px;
}
.quiz-result-header .header-links{
  display: none;
}
.retake-quiz {
  display: inline-flex;
  margin-left: 30px;
  margin-right: 0;
   a {
     font-size:  $font-size-14;
     line-height: 21px;
   }
 }
}
@media screen and (max-width: 1024px) {
  .quiz-result-header{
    padding: 0 30px;
    height: 50px;
    width: 100%;
  }
}
@import 'base';

.cbre_home_logo {
    font-size: $h2-font-size;
    color: $color-primary;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
        strong {
            font-weight: 600;
            padding-right: 3px;
        }
        @media screen and (max-width:540px) {
        max-width: 30%;  
        font-size: $font-size-14;    
    }
}

@import '~pjm-dls/dist/styles/base/variables';
.profilepopup {
    position: fixed; 
    z-index: 500;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgba($black,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    .profile-content {
      position: relative;
      background-color: $white;
      padding: 24px;
      width: 80%;
      max-width: 800px; 
      border-radius: 6px;
      box-sizing: border-box;
      overflow-y: auto;     
      box-shadow: 0px 6px 14px rgba($black, 24%);
      .row-profile {
        width: 100%;
        margin-bottom: 30px;
        .profile-heading {
          font-size: $h1-font-size;
          line-height: 30px;
          color: $color-text-dark;
        }
        .col-dec-profile {                
            display: block;
             font-weight: normal;
             font-size: $font-size-16;
             line-height: 28px;
             margin-top: 3%;
             padding: 15px 30px 15px 0;
             width: 50%;
             float: left;
             box-sizing: border-box;
             white-space: pre-line;
        }
        .col-img-profile {                
           float:left;
           display: block;
           width: 50%;
           .img-profile {
            margin-left: 0px;
            width:100%;
          }  
          }
        }
        .selected-profile-video {
          width: 70%;
          height: 300px;
          margin-left: 15%;
          margin-right: 15%;
          margin-top: 15px;

        }
        .img-profile {
          display: block;
          margin-left: 30px;
          align-items: left;
          float:left;
          width:100%;
        }  
  
    /* The Close Button */
      .close-profile {
        top: 15px;
        right: 24px;
        color: $color-text-dark;
        font-size: $h1-font-size;
        position: absolute;
        font-weight: 500;
        z-index: 9;
        cursor: pointer;
      }
    }
    .clearfix::after {
      content: '';
      clear: both;
      display: block;
    }
  }

  @media screen and (max-width: 1100px) { 
    .profilepopup {
      .profile-content {
        justify-content: center;
        .row-profile {
          margin-bottom: 0;
        }
        .selected-profile-video{
          width: 100%;
          margin: 0 !important;
        }
      }
    }
  }

@media screen and (max-width: 500px) { 
    
.profilepopup {
  position: fixed; 
  z-index: 500;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto;
  .profile-content {
    position: relative;
    margin: 10% auto; 
    padding: 24px;
    width: calc(100% - 40px); 
    border-radius: 6px;
    .row-profile{
      width: 100%;
      margin-bottom: 0px;
      .profile-heading {
        font-size: $font-size-16;
        line-height: 24px;
        font-weight: 500;
      }
      .col-dec-profile {                
        width: 100%;
        float: left;
        margin-left: 0px;
        padding: 0px;
        font-size:  $font-size-14;
        line-height: 21px;
      }
      .col-img-profile {
         width: 100%;
         margin: 15px 0;
        }
      }
      .selected-profile-video {
        width: 100%;
        height: 175px;
        margin-top: 10%;
        margin-left: 0px;
        margin-right: 0px;
      }
      .img-profile {
        display: block;
        margin-left: 10px;
        width: 100%;
        margin-top: 0px;
      }
    }
  }
}

@media screen and (max-height:900px){
  .profilepopup {   
    .profile-content {
      position: absolute;
      top: 10px;
    }
  }
}
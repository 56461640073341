@import '~pjm-dls/dist/styles/base/variables';
.footer-container {
  flex: 1;
}
.footer {
  width: 100%;
  background-color: $color-background2;
  bottom: 0px;
  .footer-row {
    padding: 3% 10%;
    display: flex;
    flex-wrap: wrap;
    .footer-col1 {
      flex-grow: 1;
      width: 22%;
      .social-links {
        .icon {
          height: 25px;
          width: 25px;
          display: inline-block;
          margin-right: 10px;
        }
        .facebook {
          background: url("../../../assets/icon/fb.png") no-repeat center
            center;
        }
        .twitter {
          background: url("../../../assets/icon/twitter.png") no-repeat center
            center;
        }
        .linkedIn {
          background: url("../../../assets/icon/linkedin.png") no-repeat center
            center;
        }
      }
    }
    .footer-col2 {
      flex-grow: 3;
      display: flex;
      .footer-item {
        overflow-wrap: break-word;
        white-space: pre-wrap;
        word-break: break-all;
        flex: 1 1 33%;
        margin-right: 30px;
        &:last-child{
          margin-right: 0;
        }
        .heading {
          font-size:  $font-size-16;
          line-height: 19px;
          color: $color-text-dark;
          margin-bottom: 15px;
        }
        .sub-heading {
          font-size:  $font-size-16;
          line-height: 18px;
          color: $color-text-dark;
          margin-top: 18px;
          margin-bottom: 6px;
        }
        a {
          font-size:  $font-size-16;
          line-height: 17px;
          color: $color-primary;
          display: block;
          margin-bottom: 15px;
          &:hover{            
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
 
    .footer {
        width: 100%;
        height: auto;
        .footer-row {
          padding: 5% 30px;
          display: block;
          flex-wrap: wrap;
          .footer-col1 {
            width: 100% !important;
            display: block !important;
            padding-left: 0px;
          }
          .footer-col2 {
            width: 100% !important;
            display: block !important;
            .footer-item {
              width: 100% !important;
              max-width:100%;
              display: block !important;
              .heading {
                margin-top: 15px;
                font-size: $h5-font-size;
                line-height: 1.4;                
              }  
              .sub-heading {
                font-size: $h6-font-size;
              } 
              a {
                font-size: $h6-font-size;
              }           
            }
          }
        }
      }
}

@media screen and (max-width: 1023px) {
  .footer .footer-row {padding: 3% 30px;}
}

@media screen and (min-width: 1024px) {
  .footer .footer-row {
    flex-wrap: nowrap;
  }
}


.static_header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  box-sizing: border-box;
  position: fixed;
  padding: 0 30px;
  z-index: 99;  
  top: 0;
  background-color:#f5f5f5;
  box-shadow: 0 0 7px rgba(0,0,0,0.3);
  .or_logo {
    width: auto;
    display: flex;
    a{
      display: flex;
    }
    img{
      width: 100%;
    }
  }
  .cbre_logo {    
    width: 80px;
    display: flex;
    a{
      display: flex;
    }
    img{
      width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .static_header {
    height: 60px;
    .or_logo{
      width: 140px;
    }
    .cbre_logo{
      width: 70px;
    }
  }
}
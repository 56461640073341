@import '~pjm-dls/dist/styles/base/variables';
.dropdown-div-suggest {
  display: inline-block;
  margin: 0px 0px 0px 12px;
  vertical-align: middle;
  &.dotContent {
    position: relative;
    &:after {
      content: '.';
      display: inline-block;
      position: absolute;
      right: -6px;
      top: 6px;
    }
    .react-dropdown-select-type-single {
      -webkit-box-align: center;
      align-items: center;
      display: grid;
      flex: 1 1 0%;
      flex-wrap: wrap;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      & > span {
        color: $color-text-dark;
        grid-area: 1 / 1 / 2 / 3;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
      }
      & > input {
        visibility: visible;
        color: $color-text-dark;
        flex: 1 1 auto;
        display: inline-grid;
        grid-area: 1 / 1 / 2 / 3;
        grid-template-columns: 0px min-content;
        box-sizing: border-box;
        margin-left: 0;
        padding: auto;
        &:focus{
          text-transform: capitalize;
        }
  
      }
    }
  
    .react-dropdown-select.locationdropdown-container[aria-expanded='true'] {
      .react-dropdown-select-type-single {
        > span {
          opacity: 0.5;
        }
      }
    }
    .locationdropdown-container[aria-expanded='false'] {
      .react-dropdown-select-input:not([value='']) {
        display: none;
      }
    }
  }
  .react-dropdown-select .react-dropdown-select-dropdown-position-bottom {
    top: 32px !important;
    overflow-x: hidden;
  }

  .react-dropdown-select {
    background: $white;
    border: 1px solid $color-text-dark !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    min-width: 250px !important;
    outline: 0;
    box-shadow: none !important;
    padding: 6px 10px;

    .react-dropdown-select-dropdown-handle svg {
      width: 26px;
      height: 24px;
      color: $color-text-dark;
    }

    .react-dropdown-select-input {
      font-size:  $font-size-14;
      line-height: 18px;
      align-items: center;
      color: $color-text-dark;
      width: 36ch;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    .react-dropdown-select-content {
      font-size:  $font-size-14;
      line-height: 18px;
      align-items: center;
      color: $color-text-dark;
    }
    .react-dropdown-select-no-data {
      text-align: left;
      background: #f6f8f6;
      font-size:  $font-size-14;
      line-height: 18px;
      color: #2b3836;
    }
    .react-dropdown-select-item {
      font-size:  $font-size-14;
      line-height: 18px;
      align-items: center;
      color: $color-text-dark;
      border-bottom: 1px solid #f6f8f6;
      background: #f6f8f6 !important;
      margin: 0px;
    }
    .react-dropdown-select-item:hover {
      background: #ecf5f1 !important;
    }
    .react-dropdown-select-item-selected {
      font-size:  $font-size-14;
      line-height: 18px;
      align-items: center;
      color: #2b3836 !important;
      background: #f6f8f6 !important;
    }
  }
}

.location-dot {
  font-size: 18px;
  line-height: 22px;
  font-feature-settings: 'ordn' on;
  color: $color-text-dark;
}

.city_hint {
    display: block;
    font-size:  $font-size-14;
    line-height: 17px;
    font-feature-settings: "ordn" on;
    color: $color-text-dark;
    margin-top: 5px;
    margin-bottom: -20px;
}
@media screen and (max-width: 500px) {
  .city_hint {
    margin-bottom: 0px;
  }
  .react-dropdown-select-input{
    width: 100% !important;
    .react-dropdown-select-input{
      width: 100% !important;
    }
  }
  .dropdown-div.wd85px {
    margin-left: 10px;
  }
   .question-container {
    font-size:  $font-size-14;
    line-height: 21px;
    background: #f9f9f9;
    border-radius: 5px;
    padding: 0px 5px;
    margin-top: 15px;
    .dropdown-div-suggest {
      display: inline-block;
      margin: 10px 0px;
      max-width: 100%;
      .react-dropdown-select {
        .react-dropdown-select-item {
          border-bottom: 1px solid #f6f8f6;
        }
      }
    }
  }
}

@import '~pjm-dls/dist/styles/base/variables';
.image-question-title {
    font-size: $h1-font-size;
    line-height: 30px;
    font-weight: 400;
    color: $color-text-dark;
    margin-bottom:15px;
}
.image-ques-sec {
    font-size: $h4-font-size;
    line-height: 24px;
    color: $color-text-dark;
}
.palette-grid {
    max-width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    .palette-tile {
        margin-top: 60px;
        display: block;
        border-radius: 12px;
        max-width: 100%;
        cursor: pointer;
        position: relative;
        .palette-row {
            text-align: left;
            width: 100%;
            .img {
                border: 1px solid #C7C8CA;
                box-sizing: border-box;
                margin: 0;
                width: 100%;
            }
        }
        .palette-row:hover + .palette-hover, .palette-hover:hover {
            display: block !important;
        }
        .palette-hover {
            display: none;
            position: absolute;
            background-color: $white;
            opacity: 0.9;
            text-align: center;
            width: 100%;
            top: 0;
            align-items: center;
            vertical-align: middle;
            a {
                img {
                    width: 100%;
                }
            }
        }
        .showHover {
            display: block;
        }
    }
    .btn {        
        float: right;
    }
}

@media screen and (max-width: 767px) {
    .image-question-title {
        font-size: $h4-font-size;
        line-height: 18px;
        margin-bottom: 5px;
        font-weight:normal;
    }
    .image-ques-sec {
        font-size: $h5-font-size;
        line-height: 21px;
        margin-bottom: 10px;
        margin-top: 10px;
        font-weight:normal;
    }
    .palette-grid {        
        width: 100%;
        margin-left: 0%;
        display: flex;
        flex: 1 1 50%;
        flex-wrap: wrap;
        max-width: 100%;
        justify-content: space-between;
        .palette-tile {
            margin: 5% 0 0 0;
            width: 100%;
            max-width: 100%;
            display: inline-block;
            border-radius: 4px;
            min-height: 100px;
            flex-direction: column;
        }
        .btn {
            font-size: $font-size-12;
            padding: 0 10px;
            height: 30px;
        }
    }
}

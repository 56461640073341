@import '~pjm-dls/dist/styles/base/variables';
@import '../../../styles/base';
.row{    
    .col.border {
        margin-right: 2%;
        margin-left: 2%;
    }  
    
    width: 100%;
    margin-bottom: 30px;
    .margin5per {
        margin-left: 5%;
        margin-right: 5%;
    }         
    .w20per {
        width: 20%;
    }
    .w30per {
        width: 30%;
    }
    .w26per {
        width: 26%;
    }
    .col {                
        display: inline-block;
        vertical-align: top;
        .tile-header {
            font-style: normal;
            font-weight: 400;
            font-size: $h3-font-size;
            line-height: 22px;
            color: $color-text-dark;
            margin-bottom: 20px;
        }
        .program-chart {
            margin-top: 10px;
            margin-left: 25%;
            height: 125px;
            width: 125px;
            &>svg{
              border-radius: 99em;
              box-shadow: 0 0 1px #d7d7d7;
            }
        }
        .chart-label{
            width: 100%;            
            .label{
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: $color-text-dark;
                display: flex;
                width: 100%;
                div {
                    vertical-align: bottom;
                    &:first-child{
                        width: calc(100% - 40px);
                    }
                }
                .ws-color-code {
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    background-color: $color-primary;
                }
                .of-color-code {
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    background-color: $graph-secondary-color;
                }
                .right{
                    float:right;
                }
            }
            .green_circle{
                position: relative;
                padding-left:25px ;
                padding-right: 10px;
                &:before{
                    background-color: $color-primary;
                    height: 20px;
                    width: 20px;
                    display: block;
                    position: absolute;
                    content: "";
                    border-radius: 99em;
                    left: 0px;
                    top: 1px;
                    }
                }
                .off_circle{
                    position: relative;
                    padding-left:25px ;
                    padding-right: 10px;
                    &:before{
                        background-color: $graph-secondary-color;
                        height: 20px;
                        width: 20px;
                        display: block;
                        position: absolute;
                        content: "";
                        border-radius: 99em;
                        left: 0px;
                        top: 1px;
                        }
                    }
        }
        .number {
            font-style: normal;
            font-weight: 400;
            font-size: $heading-5;
            line-height: 40px;
            color: $color-text-dark;
            margin-top: 52px;
            margin-bottom: 10px;
            div {
                width: 40%;
                display: inline-block;
            }
            .colon {
                width: 10%;                
            }
        }
        .desc {
            font-style: normal;
            font-weight: 400;
            font-size: $font-size-16;
            line-height: 22px;
            color: $color-text-dark;                        
            div {
                width: 40%;
                display: inline-block;
            }
            .right {               
                float: right;
            }
        }        
               
    }
    // .col#recommended-office-section {
    //     width: 30%;
    // } 
   
    .box-bg {
        margin-right: 30px;
        margin-top: 30px;
        padding: 15px;
        min-width: 200px;
        background-color: #fbfbfb;
        border-radius: 10px;
        &:last-child{
            margin-right: 0;
        }
    }
    .box-bg#density-col {
        margin-right:0%;
    }
    .border {
        border-left: 1px solid $black;
        border-right: 1px solid $black;
        padding: 0px 25px 15px 25px;
    }    
    .row-heading {
        font-style: normal;
        font-weight: 400;
        font-size: $h2-font-size;
        line-height: 38px;
        color: $color-text-dark;        
    }
    .row-desc {
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-14;
        line-height: 23px;
        color: $color-text-dark;
        margin-top: 5px;
        padding-bottom: 5px;
        display: inline-flex;
    }
    .row-heading1 {
        font-style: normal;
        font-weight: 400;
        font-size: $h2-font-size;
        line-height: 38px;
        color: $color-text-dark;
        .abbr {
            vertical-align: top;
            margin-left: 5px;
            cursor: help;
            margin-top: -10%;
            width: 15px;
            height: 15px;
        }
    }
    .__react_component_tooltip.show{
        margin-top: 0 !important;
    }
 .office-size-container .react-dropdown-select-input{        
        width: 0px;
        margin-left: 0;
    }
    .capitalise {
        text-transform: capitalize;
    }
    .space-group {
        display: inline-block;
        .tile-header {
            font-style: normal;
            font-weight: 400;
            font-size: $font-size-16;
            line-height: 32px;
            margin-bottom: 0px;
        }
        .space-bar {
            margin-top:5px;
            width: 100%;
            padding: 20px 0px;            
        }
        .individual {
            color : $color-primary;
        }
        .individual-bg {
            background: $color-primary;
            color: $white;
        }
        .group {
            color: $color-text-dark;
        }
        .group-bg {
            background: $color-background3;
            color: $color-text-dark;
        }
        .amenity {
            color: $color-primary-dark;
        }
        .amenity-bg {
            background: $color-primary-dark;
            color: $white;
        }
        .square {
            display: inline-block;
            height: 20px;
            width: 20px;
            margin-right: 10px;
            vertical-align: text-bottom;
        }
    }
    .space-group-div {
        width: 100%;
        margin : 0%;
        .space-group-dropdown {
            width: 210px;
            display: inline-block;
            vertical-align: top;
            padding-top: 20px;
            .heading {
                font-weight: 500;
                font-size:  $font-size-16;
                line-height: 18px;
                color: $color-text-dark;
                margin-bottom: 15px;
            }
            .space-group-heading {
                display: none;
                &+div{
                    width: 100%;
                }
            }
            .select {
                width: 100%;
                height: 36px;
                border: 1px solid $color-text-dark;
                box-sizing: border-box;
                border-radius: 4px;
            }
            .react-dropdown-select {
                width:100%;
                background: $white;
                border: 1px solid $color-text-dark;
                box-sizing: border-box;
                border-radius: 4px;
                font-weight: 400;
                font-size:  $font-size-14;
                line-height: 18px;
                color: $color-text-dark;
                padding-left:8px;
                -moz-appearance: none;
                -webkit-appearance: none; 
                appearance: none;    
                .react-dropdown-select-content{
                    width: calc(100% - 36px);
                    &>span{
                        display: block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }            
                .react-dropdown-select-dropdown-handle svg {
                    width: 20px;
                    height: 20px;
                    color: $color-primary !important;
                }        
                
                .react-dropdown-select-input {
                    width: 5px !important;
                }
                .react-dropdown-select-dropdown {
                    margin-top: -9px;
                    border-radius: 0px 0px 4px 4px;
                    overflow-x: hidden;
                    .react-dropdown-select-item {                      
                        padding: 10px 15px;
                        color: $color-text-dark;
                        line-height: 1.4;
                        background: $white !important;
                    }
                    .react-dropdown-select-item:hover{
                        background:  #E0EDE9 !important;                    
                    }
                }        
            }            
        }
        *:focus {
            outline: none;
        }
        .space-group-data-div {
            width:calc(100% - 240px);
            margin-left: 30px;
            display: inline-block;
            .space-group-item {
                width: calc(100% - 20px);
                padding: 20px 10px;
                border-bottom: 1px solid #D7D7D7;
                div {
                    display: inline-block;                    
                }
                .w30per {
                    width: 30%;
                }
                .w20per {
                    width: 20%;
                    text-align: center;
                }
                .w10per {
                    width: 10%;
                    text-align: center;
                }
            }
        }
    }
}
.text-center {
    text-align: center;
}
.disabled {
    opacity: 0.5;
    cursor: default;
}

.headcount-message-your-pogram{
    font-size:  $font-size-12;
    line-height: 17px;
    color: $color-error-main;
    margin-left:29%;
    margin-top:10px;
  }
  .headcount-section {
      display: none;
  }
  .program-section-colon {
      display: none;
  }
  .custom-tool-tip {
    border: 1px solid #D7D7D7 !important;
    opacity: 1 !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    display: inline !important;
    padding:  0% 3% !important;
    letter-spacing: normal;
}
.d-flex {
    display: flex;
    width: 100%;
    .row-heading1{
        display: flex;
        align-items: center;
    }
}
.css-fr0vpi-DropDown{
    width: 95px;
    top: 37px;
}
.unit-select-handle {
    width: 95px;
    display: inline-flex;
    margin: 0px 10px;
    font-style: normal;
    font-size:  $font-size-14;
    line-height: 18px;
    color: $color-text-dark;
    vertical-align: middle;
    z-index: 1;
    &>div {
        width: 100%;
        &>.react-dropdown-select {
            width: 100%;
            background: $white;
            border: 1px solid $color-text-dark;
            box-sizing: border-box;
            border-radius: 4px;
            font-style: normal;
            font-size: $font-size-14;
            line-height: 24px;
            color: $color-text-dark;
            padding-left: 8px;
            -webkit-appearance: none;
            appearance: none;
        }
        &>div .react-dropdown-select-dropdown-handle svg {
            width: 20px;
            height: 20px;
            color: $color-primary !important;
            position: relative;
            top: 2px;
        }
        &>div .react-dropdown-select-input {
                    width: 5px !important;
                }
                .react-dropdown-select-dropdown {
                    margin-top: -9px;
                    border-radius: 0px 0px 4px 4px;
                    overflow-x: hidden;
                    .react-dropdown-select-item {
                        padding: 5px 10px;
                        color: $color-text-dark;
                        background: $white !important;
                    }
                    .react-dropdown-select-item:hover{
                        background:  #E0EDE9 !important;                    
                    }
        }
    }
    div.react-dropdown-select-content {
        width: 100%;
    }
}
.react-dropdown-select-item-selected {
    background: $white !important;
    color: $color-text-dark !important;
}

.your_program{
    .col.w20per.box-bg {
        width:calc(25% - 53px);
        min-width: 100px;
        max-width: 300px;
    }
}
.your-program-bg .row .space-group-div .space-group-dropdown .react-dropdown-select .react-dropdown-select-input{
    display: none !important;
}
.unit-select-handle .react-dropdown-select .react-dropdown-select-input {
    display: none !important; 
}
.row .space-group-div .space-group-dropdown .react-dropdown-select .react-dropdown-select-dropdown{
    min-width: 130px;
}
.lang_es-ES .row .space-group-div .space-group-dropdown .react-dropdown-select .react-dropdown-select-dropdown{
    min-width: 160px;
}
.lang_fr-FR .headcount-message-your-pogram {
    line-height: 1;
    white-space: normal;
    text-align: left !important;
}

.your-program-bg{
    .row-section, .btn-headcount-section {
        .btn {
            letter-spacing: 0;
            font-size: 1rem;
            text-transform: none !important;
            padding: 0 16px;
            height: 36px;
            border-radius: 4px;
            background-color: $white;
            color: $color-primary;
            border: solid 1px $color-primary;
            &:hover{
                background-color: $outlined-text-button-hover-background;
            }
            &.disabled {
                background-color: $disabled-button-container-color;
            }
            &+.btn {
                margin-left: 16px !important;
                background-color: $color-primary;
                color: $white;
                &:hover {
                    background-color: $primary-button-hover-background;
                }
            }
            
        }
    }
}

@media screen and (min-width: 501px) and (max-width: 1024px) {
    .row .row-heading1{
        font-size: 23px;
    }
}
@media screen and (max-width: 500px) { 
    .your-program-bg{    
        .grossarea {
            display: block !important;
            margin-top: -10px !important;
            margin-left: 0 !important;
            padding-left:  3.3% !important;
            padding-right: 3.3% !important;
            margin-bottom: 20px !important;
            box-sizing: border-box;
        }
        .row.d-flex {
            flex-direction: column-reverse;
            .flex_grow.mlef4Per {
                display: none !important;
            }
        }
    }
    .your_program{
        display: none;
        .col.w20per.box-bg {
            width:100%;
            min-width: 100px;
            max-width: none;
            margin-right: 0;
        }
    }
    .row-desc#office-space-title {
        margin-top: 0;
    }
    
    .your-program-border {
        border-bottom: 1px solid hsl(162, 27%, 90%);
        margin-bottom: 5%;
        margin-right: 25px;
        margin-left: 25px;
      }
    .hc-container .headcount-comp {
        margin: 0% 2%;
    }
    .row#space-data {
        width: 400px;
        margin-top: 100px;
        height: 100px;
        display: block;
        margin-bottom: 20%;
    }
    .space-graph-row {
        width: 60%;
        margin-left: -19%;
        text-align: left;
        display: inline-block;
        transform: rotate(90deg);
    }
    .space-program-section {
        width: 50%;
        display: block;
        margin-top: -30%;
        margin-left: 12.5%;
    .space-program-description {
        width: 100%;
        margin-bottom: 30px;
        display: block;
        text-align: right;
        margin-left: 7%;
        .individual {
            color : $color-primary;
        }
        .group {
            color: $color-text-dark;;
        }
        .amenity {
            color: $color-primary-dark;;
        }
        .program-heading {
            font-size:  $font-size-14;
            line-height: 20px;
            text-align: center;
            display: inline-block;
            width: 50%;
            margin-left: 15%;
            margin-right: -8.5%;
        }
        .row-percent {
            font-size: $h3-font-size;
            line-height: 38px;
            text-align: center;
            display: inline-block;
            width: 30%;
        }
    }
   
}

    .disclaimer {
        display: none !important;
    }
    .program-section-colon {       
        display: inline-flex;
        margin-right: 5px;
    }
    .reset-label {
        display: block;
        font-style: italic;
        font-size: $font-size-16;
        line-height: 20px;     
        color: $color-primary;
        margin: 8% 0% 0% 3%;
        background-color: transparent;
        padding-right: 5%;
    }
    .your-program-bg {
        display: block;
        margin: 0% 0%;
        border-bottom: 1px solid #D7D7D7;
        background: #F1F2F2;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 20px;
    }
    .row{
        width: 100%;
        margin-bottom: 5%;
        display: block;
        .office-size-container {
            display: flex;
            flex: 1;
            align-items: center;
            width: 50%;
            .row-heading1{
                width: 100%;
                display: flex;
                align-items: center;
                .unit-select-handle{
                    font-size:  $font-size-12;
                    width: 70px;
                    &> div .react-dropdown-select-dropdown {
                        overflow: hidden;
                        width: 70px;
                    }
                    & > div > .react-dropdown-select {
                        font-size:  $font-size-12;
                        line-height: 20px;                        
                    }
                }
            }
        }
        .row-heading1 {
            display: inline-block;
            width: 22%;
            font-style: normal;
            font-size: 18px;
            line-height: 27px;
            color: $color-primary;
            vertical-align: top;
        }
        .office-space {
            display: flex;
            align-items: center;
        }
        .office-space .row-desc {
            display: inline-block; 
            width: 28%;
            font-size:  $font-size-14;
            line-height: 20px;
            color: $color-text-dark;
        }
        .office-space#profile-margin20 {
            margin-top: 15%;
        }
        .row-heading {
            display: none;
        }
        .row-section {
            display: none;
        }
        .btn {
         font-size:  $font-size-10;
         line-height: 20px;
        }
        .your-program-tile-header {
            display: block;
            font-size: $font-size-14;
            line-height: 18px;
            padding-top: 10px;
            height: 28px;
            text-align: center;
            margin-bottom: -20px;
            background: #E0EDE9;
            border-radius: 6px 6px 0px 0px;
            color: $color-text-dark;
        }
        .mrig20 {
            display: none;
        }
        .row-desc {
            font-size: $font-size-14;
            line-height: 40px;
            color: $color-text-dark;
            margin-top: 12px;
            padding: 3.3%;
        }
        .program-text {
            font-size:  $font-size-14;
            line-height: 40px;
        }
        .space-group-div {
            .space-group-data-div {
                .space-group-item {
                    font-size:  $font-size-10;
                    line-height: 14px;
                    border-bottom: 0px;
                    padding: 4% 4%;  
                    max-width: 100%;
                    width: auto;        
            }
        }
            .space-group-dropdown {
                .heading {
                    display: none;
                }
                .space-group-heading {
                    width: 20%;
                    display: inline-flex;
                    font-size:  $font-size-16;
                    line-height: 21px;
                    color: $color-text-dark;
                    margin-left: 4%;
                    &+div{
                        width: 70%;
                        max-width: 200px;
                        margin-right: 4%;
                    }
                }
                div {
                    display: flex;
                    .react-dropdown-select{
                        font-size: $font-size-12;
                        line-height: 40px;
                        padding: 2%;
                        .react-dropdown-select-dropdown .react-dropdown-select-item {
                        padding: 5%;                        
                    }
                    .react-dropdown-select-dropdown-handle {
                        svg {
                            width: 14px;
                            height: 12px;
                        }
                    }
                    }
                }
            }
        }
        
        .margin5per {
            margin: 0%;            
        }       
        .w20per {
            width: 100%;
            vertical-align: top;
        }
        .w30per {
            width: 100%;
        }
        .w26per {
            width: 100%;
        }
        .w10per {
            width: 10%;
            vertical-align: top;
        }
        .w13per {
            width: 13%;
            vertical-align: top;
        }
        .box-bg {
            padding: 0px;
        }
        .col .hc-container {
            display: inline-block;
            width: 50%;
        }
        .col#headcount-container {
            margin-top: -14%;
            text-align: center;
        }
        .col .office-space .row-desc {
            display: inline-block; 
            width: 45%;
            font-size:  $font-size-14;
            line-height: 20px;
            color: $color-text-dark;
        }
        .col {
            display: block; 
            margin-top: 5%;
            padding-bottom: 5%;
            border-bottom: 0px;
            background: #F1F2F2;
            box-sizing: border-box;
            border-radius: 6px;
            margin-right: 0;
            .desc {
                font-size: $font-size-16;
                line-height: 20px;
            }
            .tile-header {
                display: block;
                font-size: $font-size-16;
                line-height: 18px;
                padding-top: 10px;
                height: 28px;
                text-align: center;
                margin-bottom: 20px;
                background: #E0EDE9;
                border-radius: 6px 6px 0px 0px;
                .space-group {
                font-size:  $font-size-14;
                line-height: 20px;
                }
            }
            .number {
                width: 40%;
                display: inline-block;
                margin: 30%;
                font-weight: 400;
                margin-top: 0px;
                margin-bottom: 0px;
                font-size: 25px;
                .colon {
                    width: 20%;
                }
            }
            .desc div {
                font-size: $font-size-16;
                line-height: 20px;
                width: 40%;
                display: inline-block;
                margin-left: 8%;
                text-align: right;
            }
            .desc .left-sub-heading {
                text-align: left;
                margin-left: 10%;
                vertical-align: top;
            }
            .program-chart {
                height: 125px;
                width: 40%;
                display: inline-block;
                margin: 0% 5%; 
            }
            .chart-label {
                width: 49%;
                display: inline-block;
                vertical-align: top;
                margin-top: 10%;
                .label {
                    font-size: $font-size-16;
                    line-height: 20px;
                    div {
                        margin-right: 2%;
                        vertical-align: baseline;
                    }
                    .ws-color-code {
                        width: 10px;
                        height: 10.06px;
                        border-radius: 10px;
                        border: 1px solid $color-primary;
                    }
                    .of-color-code {
                        width: 10px;
                        height: 10.06px;
                        border-radius: 10px;
                        background: $graph-secondary-color;;
                        border: 1px solid $graph-secondary-color;;
                    }
                }
            }
        }
        .box-bg {            
            height: auto;
        }
        .border {
            border-left: none;
            border-right: none;
            padding: 0px;
        }
        .space-group-div {
            width: 100%;
            display: block;
            margin: 0px;
            margin-top: -5%;
            .space-group-dropdown {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                align-items: center;               
            }
            .space-group-data-div {
                width: 100%;
                display: block;
                margin: 0px;
            }
        }
    }
    .headcount-message-your-pogram{
        margin-left:2%;
        margin-top: -4%;
        margin-bottom: 1%;
    }
    .lang_fr-FR .headcount-message-your-pogram {
        text-align: center !important;
        line-height: 17px !important;
    }
    .headcount-comp#headcount-comp-container {
        margin: 5%;
    }
    .btn-headcount-section {
        margin-top: 0px;
        button{
            font-size: $font-size-12 !important;
            padding: 0 10px;
        }
    }
}


@media screen and (min-width: 501px) and (max-width: 1023px) {
    .row .box-bg {
            width: 38%;
    }
    // .row .col#recommended-office-section {
    //     width: 36%;
    // }
    .your_program{
        .col.w20per.box-bg {
            width: calc(50% - 45px);
            min-width: 100px;
            max-width: none;
            &:nth-child(2n+2){
                margin-right: 0;
            }
        }
    }
}

@media screen and (min-width:1000px){
  .no_wrap_ipad{
    white-space: nowrap !important;
  }
}

.d-flex{
    display: flex;
}
.flex-wrap{
    flex-wrap: wrap;
}

@media only screen and (min-width:500px) and (max-width: 900px) {
    .row .space-group-div .space-group-dropdown{
        width:100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
    }
    .space-group-heading+div{
        display: inline-flex;
        max-width: 300px;
    }
    .row .space-group-div .space-group-data-div{
        width: 100%;
        margin-left: 0;
    }    
}
.wd_unit{
    display: inline-flex;
}
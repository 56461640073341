@import '~pjm-dls/dist/styles/base/variables';

.header {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9;
  width: 100%;
  height: 80px;
  background: $white;
  display: flex;
  justify-content: space-between;
  padding: 16px 5%;
  align-items: center;
  box-shadow: 0 0 17px rgba($black, 20%);
  box-sizing: border-box;
  @media screen and (max-width:540px) {
    padding: 10px 30px; 
    height: 50px;
  }
  
.header-links {
    display: flex;
    align-items: center;
    .link-item {   
      cursor: pointer;
    }
    .link {      
      font-size: $h4-font-size;
      line-height: 1;
      font-weight: 500;
      color: $color-primary;
      margin-left:16px;
      &:hover {        
        text-decoration: none;
      }
      &:first-child{
        padding: 0;
      }
      @media screen and (max-width:540px) {
        font-size: $font-size-12;
      }
    }
  }
  .seperator::before {
    content: " | ";
  }
}

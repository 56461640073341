@import '~pjm-dls/dist/styles/base/variables';
.image-question-title {
    font-size: $h1-font-size;
    line-height: 30px;
    color: $color-text-dark;
    margin-bottom:15px;
}
.image-ques-sec {
    font-size: $h4-font-size;
    line-height: 24px;
    color: $color-text-dark;
}
.img-grid {
    max-width: 75%;
    margin-left: 12.5%;
    margin-right: 12.5%;
    text-align: center;
    .img-tile {
        margin-top: 45px;
        display: inline-block;
        margin-right:45px;
        background: $color-background3;
        border-radius: 10px;
        cursor: pointer;
        .img {
            border: 1px solid #C7C8CA;
            box-sizing: border-box;
            margin: 45px 20px;
            width: calc(100% - 40px);
        }
        .response {
            position: absolute;
            font-weight: 500;
            font-size: $font-size-16;
            line-height: 24px;
            color: $color-primary;
            display: flex;
            width: 100%;
            height: 45px;
            span{
                display: inline-flex;
                max-width: 79%;
                text-align: left;
                line-height: 1;
                font-size: $font-size-16;
                align-items: center;
            }
        }
        .img-title {
            margin-top: -12%;
            margin-bottom: 3%;
            font-weight: 400;
            font-size: $h4-font-size;
            line-height: 24px;
            align-items: center;
            text-align: center;
            color: $color-primary;
        }
    }
    .active {
        background: $color-background2;
        position: relative;
    }
}
.lang_fr-FR{
    .img-tile .img-title {
        font-size: $font-size-16;
    }
}
.image-icon-success {
    background: url("../../assets/icon/success_badge.png") no-repeat center center;
    margin-right: 10px;
    position: relative;
    top: 1px;
    background-size: 100%;
    display: inline-flex !important;
    margin-left: 20px;
    height: 100% !important;
}
@media screen and (max-width: 767px) {
    .image-question-title {
        font-size: $font-size-16;
        line-height: 18px;
        margin-bottom: 5px;
        font-weight:normal;
    }
    .image-ques-sec {
        font-size: $font-size-14;
        line-height: 21px;
        margin-bottom: 10px;
        margin-top: 10px;
        font-weight:normal;
    }
    .img-grid {        
        width: 100%;
        margin-left: 0%;
        display: flex;
        flex: 1 1 50%;
        flex-wrap: wrap;
        max-width: 100%;
        justify-content: space-between;
        .img-tile {
            margin: 0;
            margin-bottom: 4%;
            width: calc(48% - 16px);
            padding: 8px;
            display: inline-flex;
            border-radius: 4px;
            min-height: 80px;
            flex-direction: column;            
            .img-title {
                font-size: $font-size-10;
                font-weight: normal;
                margin-top: 7px;
                margin-bottom: 0;
                line-height: 1;
            }
            .img {
                max-width: 100%;
                width: 100%;
                margin: 0;
            }
            .response {
                font-size: 0px;
                line-height: 12px;
                margin-top: 4px;
                opacity: 0.95;
                height: auto;
                padding-left: 1%;
                width: 80%;
                span{
                    font-size: 0;
                }
            }
        }
    }
    .image-icon-success {
        background: url("../../assets/icon/success_badge.png") no-repeat center 0px;
        background-size: 100% !important;
        margin-right: 0px;
        box-shadow: 0 0 4px rgba(255, 255, 255, 0.7);
        border-radius: 99px;
        margin-left: 0;
        height: 20px !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 1300px) {
    .img-grid {
        max-width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        .img-tile {
            margin-bottom: 5%;
            margin-top: 10px;
            margin-right: 10px;
            width: 45%;
           
        }
    }
}
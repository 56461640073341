

.loader {
  margin: 10% auto;
  border: 3px solid #f3f3f3;
  border-radius: 60%;
  border-top: 3px solid #006747;
  width: 65px;
  height: 65px;
  -webkit-animation: spin1 2.0s linear infinite; /* Safari */
  animation: spin1 2.0s linear infinite;
}

/* Safari */
@-webkit-keyframes spin1 {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin1 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@import '~pjm-dls/dist/styles/base/variables';
.word-question-title {
    font-size: $h1-font-size;
    line-height: 30px;
    color: $color-text-dark;
    margin-bottom:15px;
}
.word-ques-sec {
    font-size: $h4-font-size;
    line-height: 24px;
    span {
        color: $color-primary;
        font-weight: 500;
    }
}
.word-grid {
    max-width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    .word-tile {
        border: 1px solid $color-primary;
        box-sizing: border-box;
        border-radius: 23.8095px;
        cursor: pointer;
        font-size: $h4-font-size;
        line-height: 22px;
        font-feature-settings: 'ordn' on;
        color: $color-primary; //#7D7D7D;
        padding: 1.4% 0%;
        margin-top: 40px;
        margin-right: 45px;
        width: 25%;
        display: inline-block;
        text-align: center;
    }
    .active {
        background: $color-primary;
        color: $white;
    }
}

@media screen and (max-width: 500px) {
    .word-question-title {
        font-size: $h4-font-size;
        line-height: 21px;
        font-weight: normal;
    }
    .word-ques-sec {
        font-weight: 400;
        font-size: $h5-font-size;
        line-height: 21px;
        color: $color-text-dark;
    }
    .word-grid {
        max-width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 15px;
        .word-tile {            
            width: 42%;
            font-size: $h5-font-size;
            line-height: 20px;
            margin-top: 20px;
            margin-right: 20px;
            font-weight: normal;
        }
    }
 }

 @media screen and (min-width: 501px) and (max-width: 1024px) {
    .word-grid {
        max-width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        .word-tile {            
            width: 35%;
        }
    }
 }
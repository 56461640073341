@import '~pjm-dls/dist/styles/base/variables';
.progress-bar {
  width: 225px;
  font-size: $h6-font-size;
  line-height: 14px;
  color: $color-primary;
  .progress-text {    
    margin-bottom:15px;
    margin-left:-10px;
    line-height: 17px;    
  }  
  .opacity {
    opacity: 0.5;
  }
}
text{
  zoom:500%;
}
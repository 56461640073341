.app {
  margin-top:20%;
  text-align: center;
}
.container {
  margin-top:90px;
  margin-left:2%;
  margin-right:2%;
}
button {
  box-sizing: border-box;
  margin: 0em;
  font: Arial;
  padding: 0px;
  border-width: 0px;
  border-style: none;
  border-color: transparent;
  border-image: none;
  cursor: pointer;
}

/* common CSS  */
.align-center {
  align-items: center;
  text-align: center;
}
.icon {
  height: 20px;
  width: 20px;
  display: inline-block;
  vertical-align: text-top;
}
.mtop10 {
  margin-top: 10px;
}
.mtop20 {
  margin-top: 20px;
}
.mtop40 {
  margin-top: 40px;
}
.mtop50 {
  margin-top: 50px;
}
.mrig20{
  margin-right:20px;
}
.mlef5 {
  margin-left: 5px;
}
.mlef10 {
  margin-left: 10px;
}
.mlef20{
  margin-left:20px;
}
.mlef50{
  margin-left:50px;
}
.mlef4Per {
  margin-left: 4%;
}
.mbot10 {
  margin-bottom: 10px;
}
.mbot20 {
  margin-bottom: 20px;
}
.mbot40 {
  margin-bottom: 40px;
}
.p20 {
  padding: 20px;
}
.text-bold {
  font-weight: bold !important;
}

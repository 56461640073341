@import '~pjm-dls/dist/styles/base/variables';
.result-modalpopup {  
  position: absolute;
    top: 108px;
    right: 10%;
    width: 346px;
    height: 120px;

  .result-modal-content {
    padding: 24px;
    background: $white;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0px 6px 14px rgba($black, 24%);  
    margin-top: 3%;
    float: right;
    width: 346px;
    min-height: 97px;
    position: absolute;
    /* The Close Button */
    .result-close {
      color: $color-text-dark;
      right: 16px;
      top: 9px;
      line-height: 1;
      font-size: $h1-font-size;
      position:absolute;
    }
    .result-close:hover,
    .result-close:focus {
      color: $color-text-dark;
      text-decoration: none;
      cursor: pointer;
    }    
  }

  .result-modal-detail-row {
    align-items: center;
    font-style: normal;
    font-weight: 500;
    .copy_input_box {
      display: flex;
      align-items: center;
    }
    .result-input_Link {
      background: $white;
      border: 1px solid rgba($color-text-dark, 100%);
      box-sizing: border-box;
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 36px;
      flex-grow: 1;
      font-size:  $font-size-14;
      line-height: 21px;
      font-weight: 400;
      color: $color-text-dark;
      max-width: calc(100% - 64px);
      padding: 12px;
      &+button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
 
    }
    label {
      display: flex;
      font-size:  $font-size-14;
      line-height: 16px;
      color: $color-text-dark;
      margin-bottom: 10px;
      text-align: left;
      font-weight: 400;
      /* identical to box height */
    }
  }
  .copy-message{
    font-size:  $font-size-12;
    line-height: 1;
    color: $color-primary;
    text-align: center;
    margin-top:10px;
  }
}

@media screen and (max-width: 500px) {
  .result-modalpopup .result-modal-content {
    margin-left: 0%;
    margin-top: 9%;
    max-width: 95%;
  }
  .result-modalpopup{
    width: 80%;
    top:45px;
  }
}

@media screen and (min-width: 501px) and (max-width: 1023px) {  
  .result-modalpopup .result-modal-detail-row label {
    font-size:  $font-size-10;
    line-height: 15px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366) {
  .result-modalpopup .result-modal-content
     { 
    margin-left: 50%;
    margin-top: 4.5%;
  }
}
@import '~pjm-dls/dist/styles/base/variables';
.grid-item {
  font-weight: normal;
  font-size: $font-size-16;
  line-height: 55px; 
  text-align: right;
  margin-left: 50px;
  width: 80%;
  .cost-estimate-grid {
    margin-bottom: 30px;
    .header-1 {
      background-color: $white !important;
      display: flex;
      flex-direction: row !important;      
      .colm-1 {
        width: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
        font-size: $font-size-16;
        line-height: 38px;
        color: $color-text-dark;
        float: left;
        display: inline;
        ;
        span {
          font-size:  $font-size-12;
          padding-right:10px;
        }
      }
      label {font-weight: normal;}
    }
    .grid-item-col{
      display:flex ;
      flex-wrap: wrap;
      font-weight: normal;
      font-size: $font-size-16;
      line-height: 55px; 
      text-align: right;
      margin: 0 !important;
      &:nth-of-type(odd) {
        background-color:$white !important;
      }  
      &:nth-of-type(even) {
        background-color: #F6F8F6;
      }
      .col-item{
        border: 1px solid #ECF5F1 !important;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        position: relative;
        padding-left: 15px;
        padding-right:65px;
        &:first-child{
          text-align: left !important;
        }
      }
      .col-width-item {
        flex: 0 0 25%;
        max-width: 25%;
        padding-right: 10px;
      }
      .textleft {
        text-align: left;
      }
    }
    .total {
      font-weight: bold;
      .col-item{
        border: 0 !important;
       }
    }
    
  }
}

.description-text{
  font-size:  $font-size-12;
  line-height: 1.4;
  color: $color-text-dark;
  text-align: left;
  &+.description-text {
    margin-bottom: 0;
    margin-top: 15px;
  }
}
.exceedMsg{
  line-height: 1.4;
  padding-top: 20px;
  padding-bottom: 20px;
}
.textCenter{
  text-align: center;
}
.single_col {
  flex: 1 1 100% !important;
  text-align: center !important;
  padding: 15px 0 !important;
}
.grid-item .cost-estimate-grid .grid-item-col
  .single_col.col-item {
    flex: 1 1 100% !important;
    text-align: center !important;
    padding: 20px 0 !important;
  } 
.width-100{
  width: 100% !important;
}
.lang_es-ES, .lang_en-UK, .lang_fr-FR {
  .quiz-result-container {
    .section-container {
      .nav-menu-item {
        .nav-menu-item-body {
          .grid-item {            
            .cost-estimate-grid {
              .header-1 {
                .colm-1:first-child {
                  width: 40%;
                }
                .colm-1:last-child {
                  width: 60%;
                }
                @media only screen and (max-width: 500px) {
                  .colm-1 span {
                    display: inline;
                  }
                }
              }
              .grid-item-col {
                line-height: 32px;
                border-top: 1px solid #ECF5F1;
                border-bottom: 1px solid #ECF5F1;
                .col-item {
                  padding: 10px;
                }
                .col-item:first-child {
                  flex-grow: .4;
                  padding-right: 10px;
                }
                .col-item:last-child {
                  flex-grow: .6;
                  text-align: center;
                  padding-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

  .lang_en-SG {
    .quiz-result-container {
      .section-container {
        .nav-menu-item {
          .nav-menu-item-body {
            .grid-item {
              width: 90%;
              @media only screen and (max-width: 1100px) and (min-width:501px) {
                width: 100%;
              }
              .repayment-grid {
                margin-top: 50px;
                .header-1 {
                  text-align: left;
                }
              }
              .repayment-grid {
                  margin-top: 25px;
              }
              .header-1 {
                font-size: $font-size-16;
                font-weight: 600;
                line-height: 38px;
                color: $color-text-dark;
                border:solid 1px #ccc;
                border-bottom: 0;
                box-sizing: border-box;
                span {
                  margin-left: 10px;
                }
                &+div{                  
                border:solid 1px #ccc;
                }
              }
              
              .grid-item-col{
                line-height: 1.5;
                .col-item{
                padding-top: 15px;
                padding-bottom: 15px;
                }
              }
            }
            .repayment-notes {
              font-size:  $font-size-14;
              line-height: 22px;
              color: #3D3D3D;
              text-align: left;
              margin-top: 25px;
              span {
                margin-right: 10px;
                font-weight: bold;
              }
              @media only screen and (max-width: 500px) {
                font-size:  $font-size-10;
                line-height: 15px;
              }
            }
            @media only screen and (max-width: 500px) {
              .cost-estimate-grid{
                padding: 10px !important;
              }
              .header-1 {
                font-size: 12px !important; 
                border: 0 !important;
                border-bottom: 1px #ccc solid !important;
                &+div{
                  border: 0 !important;
                }
              }
              .description-text-table +.cost-estimate-grid{
                margin-top: 30px;
              }
              .cost-estimate-grid {
                .grid-item-col {
                  border-bottom-color:#ccc;
                  &:last-child{
                    border-bottom: 0;
                  }
                }
              }
              .description-text-table{
                font-size:  $font-size-10;
                margin-bottom: 10px;
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
    .description-text-table{
      font-size:  $font-size-14;
      line-height: 1.4;
      color: #3D3D3D;
      margin-top: -15px;
      margin-bottom: 40px;
      text-align: left;
    }
    .grid-item {
      margin-left: 0;
    }
  }
  .exceedMsg{
    line-height: 1.4;
    padding-top: 20px;
    padding-bottom: 20px;
  }

@media only screen and (max-width: 500px) {
  .grid-item {
    margin-left: 0px !important;
    width: 100% !important;
    margin-top: 0px;
    .cost-estimate-grid {
      padding: 23px 10px;
      margin: 0px;
      border-radius: 4px;
      background: white;
      .header-1 {
        .colm-1{
          font-size: 9px;
          width: 100%;
          align-items: 20px;
          line-height: 20px !important;
          color: $color-text-dark;
          padding-bottom: 10px;
          font-weight: normal;
          border-bottom: 1px solid $black;
          text-align: right;
          span {
            display: none;
          }
        }
      }
      .grid-item-col {
        font-size: 13px !important;
        line-height: 13px !important;
        border-bottom: 1px solid $black;
        color: $color-text-dark;
        &:nth-child(1) {
          border-bottom: 1px solid $color-text-dark;
        }
        .col-item {
          padding: 15px 10px;
          border: 0px solid #ECF5F1 !important;
          background-color: white;
          &:first-child {
          white-space: nowrap;
          }
        }
        .col-width-item {
          flex: 0 0 35%;
          max-width: 35%;
        }
      }
    }
    .repayment-grid {
      margin-top: 50px;
    }
  }
  .description-text{
    font-size:  $font-size-10;
    font-weight: normal;
    line-height: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
  }  
}
@media only screen and (min-width: 501px) and (max-width: 1024px) {
  .grid-item {
    width: 100%;
    margin-left:0;
  }
  .description-text {
    margin-left: 0;
  }
}

button+button {
  &.mlef20 {
    margin-left: 0 !important;
  }
}
@import '~pjm-dls/dist/styles/base/variables';
.row-profile{
    width: 100%;
    margin-bottom: 30px;    
    .w20per {
        width: 20%;
    }
    .colStyle {                
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        .text-div {
            text-align: center;
            .heading {
                font-size:$h1-font-size;
                text-align:center;
                color:$color-text-dark;
                margin-top: 50px;
            }
            .capitalize-word {
                text-transform: capitalize;
            }
            .heading-text {
                font-size:$h3-font-size;
                margin-top: 23px;
                color:$color-text-dark;
                white-space: pre-line;
                text-align: center;
                padding: 5%;
            }
            .heading-text-readmore {
                font-size:$h3-font-size;
                color:$color-primary;
                margin-top: 50px;
                margin-bottom:40px;
                text-align: center;
                .style-Button{
                    margin-left: 10px;
                    vertical-align: bottom;
                }
            }
        }
        .img-div {
            margin-top:20px;
            align-items: center;
            width: 100%;
        }
        .img-industrial {
            width: 200px;
            height: 100px;
            align-items:center;
            display: block;
            margin-left: auto;
            margin-right: auto;
            top:60px
          }  
          .img-other {
            display: block;
             margin-left: auto;
             margin-right: auto;
             align-items:center;
             width: 165px;
             height: 102px;
             top:50px
          }  
          .img-fresh {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100px;
            height: 129px;
            top:60px
          }  
    }
    .box-bg-style {
        margin-right: 5%;
        margin-top: 3%;
        width: 30%;
        border-radius: 24px;
        background-color: #F1F2F2;
    }
    :last-child {
        margin-right: 0%;
    }
}
.styles-heading{
    font-size: $font-size-16;
    line-height: 38px;
    color: $color-text-dark;
}

.qcont:first-letter {
    text-transform: capitalize
  }

  .pop_styles_parent{
      display: flex;
  }
.lang_en-SG {
    .row-profile{
        .box-bg-style {
            background: none;
            .text-div{
                display: none;
            }
        }
        .pop_styles_parent{
            width: 80%;
            margin:0 auto ;
        }
        .colStyle{
            .heading {
                font-size: 30px;
                line-height: 38px;
                letter-spacing: .02em;
                color: $black;
                margin: 10px 0;
                text-align: center;
                width: 100%;
                text-transform: uppercase;
                &.elegant {
                    color: #8ad18c;
                }
                &.expressive {
                    color: #fa9f78;
                }
                &.essential {
                    color: #535f73;
                }
                &.effortless {
                    color: #535f73;
                }
            }
            .img-other{
                width: 100%;
                margin:0;
                height: auto;
            }

        } 
    }
}
.overflow-hidden{
    overflow-y: hidden;
}
@media screen and (max-width: 540px) { 
    .pop_styles_parent{
        flex-direction: column;
    }
    .row-profile {
        width: 95%;
        margin-bottom: 30px;
        .colStyle {
            display: block;
            padding-bottom: 0px;
            border-bottom: 1px solid #D7D7D7;
            .img-div {
                display: inline-block;
                width: 30%;
                margin-left: 5%;
                .img-other{
                    width: 75px;
                    height: 75px;
                }
            }
            .text-div {
                display: inline-block;
                width: 60%;
                margin-left: 5%;
                vertical-align: super;
                text-align: left;
                .heading {
                    text-align: left;
                    font-size:  $font-size-14;
                    line-height: 21px;
                    font-weight: normal;
                    margin-top: 15px;
                    margin-bottom: 0px;
                }
                .heading-text {
                    text-align: left;
                    font-size:  $font-size-12;
                    margin-top: 15px;
                    margin-bottom: 0px;
                    padding: 0;
                }
                .heading-text-readmore{
                    text-align: left;
                    font-size:  $font-size-12;
                    margin-top: 10px;
                    margin-bottom: 20px;
                    .style-Button {
                        margin-left: 5px;
                        height: 12px;
                        width: 12px;
                        vertical-align: middle;
                        margin-top: -1px;
                    }
                }
            }
        }
        .box-bg-style {            
            width: 100%;
            padding: 5px;
            margin-top: 5%;
            background-color: #F1F2F2;
            border-radius: 10px;
        }        
        .styles-heading{
            margin-left: 10px;
        }
    }
    .styles-heading {
        font-size: $font-size-14;
        line-height: 22px;
    }

    .lang_en-SG {
        .quiz-result-container {
            .section-container {
                .section {
                    .nav-menu-item-body {
                        .row-profile {
                            .colStyle {
                                .img-div {
                                    vertical-align: top;
                                }
                                .text-div {
                                    .heading-text {
                                        padding: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .lang_en-SG {
        .row-profile{
            .box-bg-style {
                background: none;
                margin:0;
                padding: 0;
                width: 100%;
            }
            .pop_styles_parent{
                width: 100%;
            }
            .colStyle{
                border-width: 0;
                margin: 0;
                padding: 0;
                margin-top: 30px;
                .img-div{
                    width: 100%;
                    margin-left: 0;
                    padding-left: 0;
                }
                .heading {
                    font-size: $h2-font-size;
                    line-height: 1.5;
                    margin-bottom: 0;
                }
                .img-other{
                    width: 100%;
                    margin:0;
                    height: auto;
                }
    
            } 
        }
    } 
}
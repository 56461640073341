@import '../../../styles/base';

.MuiIconButton-root {
    color: $color-text-dark;
}
.MuiAppBar-colorPrimary{
    background-color: $white !important;
}
.MuiToolbar-root{
    justify-content: end;
    height: 80px;
}
.MuiDialog-container {
    margin-top: 80px;
    height: calc(100% - 80px) !important;
}

@media screen  and (max-width: 500px){
    .MuiToolbar-regular{
        height: 50px;
    }
    .MuiDialog-container {
        margin-top: 50px;
        height: calc(100% - 50px) !important;
    }
}
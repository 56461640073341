@import '~pjm-dls/dist/styles/base/variables';
.modal {
  position: fixed; 
  z-index: 500;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: hidden;
  background-color: rgba($black,40%);

  .modal-content {
    position: absolute;
    background-color: $white;
    padding: 24px;
    width: 45%; 
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 6px;
    box-shadow: 0px 6px 14px rgba($black, 24%);


  /* The Close Button */
    .close {
      top: 16px;
      right: 22px;
      color: $black;
      line-height: 1;
      font-size: $h1-font-size;
      position: absolute;
    }

    .close:hover,
    .close:focus {
      color: $black;
      text-decoration: none;
      cursor: pointer;
    }

    .popup-heading{
      display: flex;
      align-items: center;
      margin: 0;
      margin-bottom: 40px;
      .warn-img {
        display: inline-flex;
        color: $color-error-main;
      }
      .heading {
        font-style: normal;
        font-size: $h2-font-size;
        line-height: 24px;
        color: $color-text-dark;
        margin-left: 10px;
        font-weight: 500;
      }
    }

    .popup-desc {
      font-style: normal;
      font-size:  $font-size-16;
      line-height: 24px;
      color: $color-text-dark;
    }
    .spacing {
      border-bottom: 1px solid #D9E1E0;
      padding-bottom: 40px;
    }

    .popup-btn-container {
      text-align: right;
      vertical-align: middle;
      margin-top: 24px;      
    }
  }
}
@media screen and (min-width: 541px) and (max-width: 1200px) {
  .modal .modal-content {
    width: 70%;
    max-width: 550px;
  }
}

@media screen and (max-width: 500px) { 
  .modal .modal-content {
    width: 75%;
    border-radius: 10px;
    .popup-btn-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      &>button {
        margin-bottom: 16px;
        margin-right: 0 !important;
      }
    }
    .popup-heading {
      .heading {
      font-size: 18px;
      font-weight: normal;
      }
    }
  }
}
@import '~pjm-dls/dist/styles/base/variables';
.question-container {
    display: block;
    font-size: $h4-font-size;
    line-height: 22px;
    color: $color-text-dark;
    margin-top: 30px;
    .dropdown-div {
        display: inline-block;
        margin: 0px 0px 0px 12px;
        vertical-align: middle;
        &.wd280px{
            .react-dropdown-select-content{
                width: 90%;
                span{
                    display: block;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    white-space: nowrap;
                }
            }
        }

    .react-dropdown-select {
        width:100%;
        background: $white;
        border: 1px solid $color-text-dark;
        box-sizing: border-box;
        border-radius: 4px;        
        font-size:  $font-size-14;
        line-height: 18px;
        align-items: center;
        color: $color-text-dark;
        padding: 6px 10px;
        -moz-appearance: none; 
        -webkit-appearance: none; 
        appearance: none;
        .react-dropdown-select-dropdown-handle svg {
            width: 26px;
            height: 24px;
            color: $color-text-dark;
        }        
        .react-dropdown-select-content {
            font-size:  $font-size-14;
            line-height: 18px;
            align-items: center;
            color: $color-text-dark;
        }
        .react-dropdown-select-input {
            width: 5px !important;
            display: none;
        }
        .react-dropdown-select-dropdown {
            margin-top: -13px;
            border-radius: 0px 0px 7px 7px;
            max-height: 146px !important;
            overflow-x: hidden;
            .react-dropdown-select-item {
                min-height: 30px !important;
                font-size:  $font-size-14;
                line-height: 18px;
                align-items: center;
                margin: -1px;
                color: #2B3836 !important;
                background: #F6F8F6 !important;                
            }
            .react-dropdown-select-item:hover{
                background: #ECF5F1 !important;                    
            }
        }        
    }
}

    *:focus {
        outline: none;
    }
    input:focus::placeholder {
        color: transparent;
    }
    select::-ms-expand {
        display: none;
    }
    ::-ms-clear {
        display: none;
    }    
}


@media screen and (max-width: 500px) {
    .question-container {
        display: block;
        width:100%;
        font-size:  $font-size-14;
        line-height: 21px;
        background: #F9F9F9;
        border-radius: 5px;
        padding: 0px 5px;
        margin-top: 15px;
        .dropdown-div {
            display: inline-block;
            margin: 10px 0px;
            span {
                margin: 0px;
            }
        }
        select {
            margin-top: 5px;
        }
    }  
 }
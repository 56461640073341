@import '~pjm-dls/dist/styles/base/variables';
.error-header {
  height: 60px;
  .error-header-logo {
    position: relative;
    margin-left: 10%;
    margin-top: 5%;
    margin-bottom: 5%;
    .logo {
      width: auto;
      cursor: pointer;
    }
  }
}
.error-container {
  color: #696e6d;
  line-height: 55px;
  text-align: center;
  letter-spacing: -1.78px;
  .error-heading {
    font-size: 42px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  .error-paragraph {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 29px;
  }
}
.error-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 156px;
  font-size:  $font-size-16;
  line-height: 20px;
  text-align: center;
  margin-top: 1.2rem;

  width:100%;
  .back-btn {
    width: 288px;
    color: #006747;
    transition: all 0.3s linear;
    background: $white;
    box-shadow: 0px 1px 2px rgba(0, 114, 81, 0.25);
    border-radius: 7px;
    padding: 12px 20px;
    text-decoration: none;

  }
  .contact-btn {
    width: 135px;
    border: 1px solid #e0ede9;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 114, 81, 0.25);
    background: #006747;
    color: white;
    padding: 12px 20px;
    text-decoration: none;
    margin-left: 1.2rem;
    border-radius: 7px;

  }
}

@media screen and (min-height: 1081px) and (max-height: 1384px) {
  .error-container {
    margin-top: 120px;
  }
}


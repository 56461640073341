@import '../../styles/base';
.design_palette {
  margin-top: 0px;
  .design_palllete_header {
    background: $white;
    display: flex;
    width: 80%;
    padding: 0 10%;
    box-shadow: 0px 4px 4px rgb(242 240 232 / 50%);
    justify-content: space-between;
    display: none;
  }
  .top-content {
    background: rgba(44, 44, 44, 0.75);
    .img-container {
      background-size: cover;
      background-position: center;
      width: 100%;
      .text-content {
        text-align: left;
        padding: 3% 5% 18% 5%;
        .div-text {
          width: 85%;
          display: inline-block;
          vertical-align: top;
          .text {
            font-weight: 600;
            font-size: $h1-font-size;
            line-height: 40px;
            background: $white;
            width: fit-content;            
            padding: 0px 10px;
            color: $color-primary;
            &+.text {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
  .brand-info {
    position: absolute;
    margin-top: -65px;
    background: $white;
    color: $color-text-dark;
    text-align: center;
    width: 30%;
    left: 35%;
    font-weight: 400;
    border-radius: 2px;
    padding: 15px 0;
    box-shadow: 0px 0px 17px rgba($black, 20%);

    .heading {
      font-size: $heading-2;
      line-height: 1;
      margin-bottom: 10px;
    }
    .desc {
      font-size: $h1-font-size;
      font-weight: 600;
    }
  }
  .main-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 85px;
    .bgwhite {
      background: $white;
    }
    .bggreen {
      background-color: $section-bg-color-1;;
    }
    .pbot50 {
      padding-bottom: 50px;
    }
    .ptop50 {
      padding-top: 50px;
    }
    .section {
      width: 100%;
      .div-content {
        width: 80%;
        margin: 60px auto;
        display: flex;
        justify-content: space-between;
        &.other_palletes {
          flex-direction: column;
        }
        .div-text-center {
          width: 70%;
          margin: 0px auto;
          text-align: center;
          .heading {
            font-size: $heading-5;
            line-height: 40px;
            color: $color-text-dark;
          }
          .text {
            font-weight: 400;
            font-size: $font-size-16;
            line-height: 28px;
            color: $color-text-dark;
          }
        }
        .div-img {
          width: calc(100% - 260px);
          display: inline-block;
          text-align: center;
          img {
            width: 100%;
          }
          & + .div-text {
            padding-left: 100px;
            padding-right: 0;
          }
        }
        .div-text {
          width: auto;
          padding-right: 100px;
          display: inline-flex;
          vertical-align: top;
          flex-direction: column;
          align-items: baseline;
          justify-content: center;
          .heading {
            font-size: $heading-3;
            color: $color-text-dark;
            margin-bottom: 14px;
            line-height: 56px;
            font-weight: 400;
          }
          .text {
            font-weight: 400;
            font-size: $heading-5;
            color: $color-text-dark;
            line-height: 40px;
          }
        }
        .div-imgs {
          width: 100%;
          display: inline-block;
          margin-top: 30px;
          .img {
            display: inline-block;
            width: 15%;
            margin-left: 2%;
            background: #e6e7e8;
            border-radius: 4px;
            cursor: pointer;
            img {
              border: 1px solid #c7c8ca;
              box-sizing: border-box;
              margin: 4% 3% 2% 3%;
              width: 94%;
            }
          }
          .selected {
            background: #026850;
          }
          :first-child {
            margin-left: 0%;
          }
        }
        .other-palletes {
          display: flex;
          > div {
            margin-right: 30px;
            .heading {
              font-size: $heading-6;
              line-height: 38px;
              color: $color-text-dark;
              margin: 10px 0px;
              text-transform: uppercase;
              text-align: center;
              &.essential {
                color: #8ad18c;
              }
              &.expressive {
                color: #fa9f78;
              }
              &.elegant {
                color: #535f73;
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
          img {
            width: 100%;
          }
        }
        .iframe_container {
          width: 100%;
        }
      }
      &.col_rev {
        .div-text {
          align-items: flex-end;
        }
      }
    }
  }
  .close_page {
    position: fixed;
    border-radius: 99em;
    width: 40px;
    height: 40px;
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    right: 10px;
    top: 70px;
    font-size: $h2-font-size;
    background: $color-primary;
    line-height: 42px;
    color: $color-text-dark;
    z-index: 11;
    display: none;
  }
  & + .footer-container {
    height: 5px;
  }
}
.cursorp {
  cursor: pointer !important;
}
.lang_en-SG {
  .div-imgs {
    .img {
      cursor: auto !important;
    }
  }
}
.disabled_container {
  opacity: 0.4;
}
.pos_rel {
  position: relative;
}
.pad_btm1 {
  padding-bottom: 1px;
}

.max_wd100 {
  img {
    max-width: 100%;
  }
}

.overflow_hidden {
  overflow-x: hidden;
}
.mt_m30 {
  margin-top: -30px !important;
}
.alice-carousel__stage-item img {
  width: 100%;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  width: 40px !important;
  position: absolute;
  top: calc(50% - 20px);
  height: 40px;
  z-index: 99;
  padding: 0 !important;

  & > div {
    display: flex;
    height: 100%;
    width: 100%;
    p {
      display: flex;
      height: 100%;
      width: 100%;
      span {
        display: flex;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.alice-carousel__next-btn-item,
.alice-carousel__prev-btn-item {
  background: url('../../assets/icon/arrow_nav.png');
  background-repeat: no-repeat;
  &.__inactive {
    background-position-y: -55px;
  }
}
.alice-carousel__next-btn-item {
  background-position-x: right;
}
.alice-carousel__prev-btn {
  left: -50px;
}
.alice-carousel__next-btn {
  right: -50px;
}
.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  content: '' !important;
}
.sliderimg {
  width: 100%;
  height: 350px;
  object-fit: cover;
}
.auth-check-div {
  max-width: 400px !important;
  padding: 15px !important;  
  margin-top: 100px !important;
}
.full_width_container {
  height: 400px;
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  &.first_container {
    a {
      margin-right: 5%;
      margin-left: auto;
    }
  }
  a {
    margin: 0;
    text-decoration: none;
    margin-left: 5%;
    &.btn_white {
      font-style: normal;
      font-weight: 400;
      font-size: $heading-3;
      line-height: 1;
      color: $color-text-dark;
      background: $white;
      padding: 20px;
      box-shadow: 0px 1px 2px rgb(0 114 81 / 25%);
      border-radius: 4px;
    }
  }
}
.item {
  position: relative;
  .Overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba($black, 40%);
    color: $white;
    display: flex;
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: $h1-font-size;
    flex-direction: column;
    font-weight: 500;
    span {
      font-size: $font-size-16;
      display: flex;
      font-weight: 400;
      width: 100%;
      justify-content: center;
    }
  }
}
@media screen and (max-width: 767px) {
  .close_page {
    display: block !important;
  }
  .design_palette {
    .sliderimg {
      height: 200px;
    }
    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
      display: none !important;
    }
    .alice-carousel {
      width: 100% !important;
    }
    .sliderimg {
      height: 200px !important;
    }
    .top-content {
      .img-container {
        .text-content {
          padding-left: 30px;
          padding-right: 30px;
          .div-text {
            width: 100%;
            .text {
              font-weight: normal;
              font-size: $h3-font-size;
              line-height: 26px;
            }
          }
        }
      }
    }
    .brand-info {
      width: 60%;
      left: 20%;
      margin-top: -35px;
      padding: 10px 0;
      .heading {
        font-size: $heading-6;
        margin-bottom: 5px;
      }
      .desc {
        font-size: $h5-font-size;
      }
    }
    .main-content {
      width: 100%;
      display: block;
      margin-top: 50px;
      .section {
        width: 100%;
        display: flex;
        .pbot50 {
          padding-bottom: 0px;
        }
        .ptop50 {
          padding-top: 20px;
        }
        .div-content {
          width: calc(100% - 60px);
          margin: 40px 30px;
          flex-flow: column;
          .other-palletes {
            > div {
              .heading {
                font-size: $h2-font-size;
              }
            }
          }
          .div-text-center {
            width: 100%;
            .heading {
              font-size: $h3-font-size;
              line-height: 1.5;
              font-weight: normal;
              margin-bottom: 0;
            }
            .text {
              font-size:  $font-size-14;
              line-height: 1.5;
            }
            .mtop40 {
              margin-top: 20px;
            }
            .mtop20 {
              margin-top: 10px;
            }
          }
          .div-text {
            padding-right: 0px;
            margin-bottom: 20px;
            .heading {
              font-size: $h1-font-size;
              line-height: 18px;
              font-weight: normal;
              margin: 0 0 10px;
            }
            .text {
              font-size:  $font-size-16;
              line-height: 16px;
              margin-left: 0;
              padding: 0;
            }
          }
          .div-img {
            width: 100%;
            & + .div-text {
              padding-left: 0px;
              margin-bottom: 20px;
              align-items: flex-start;
            }
          }
          .div-text + .div-img {
            width: 100%;
            margin-left: 0px;
            margin-right: 0;
          }
          .div-imgs {
            width: 100%;
            display: inline-block;
            margin-left: 0px;
            margin-top: 20px;
            .img {
              width: 30%;
              margin-left: 5%;
              img {
                margin: 4% 3% 2% 3%;
                width: 94%;
              }
            }
            :first-child,
            :nth-child(4) {
              margin-left: 0%;
            }
            :nth-child(4),
            :nth-child(5),
            :nth-child(6) {
              margin-top: 5%;
            }
          }
          .mbot40 {
            margin-bottom: 25px;
          }
          .mtop40 {
            margin-top: 15px;
          }
          .other-palletes {
            flex-direction: column;
            > div {
              margin-right: 0;
              margin-bottom: 30px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
        &.col_rev {
          .div-content {
            flex-flow: column-reverse;
            align-items: flex-start;
          }
        }
      }
    }
  }
  .auth-check-div {
    max-width: 300px !important;
    padding: 10px !important;
  }
  .design_palette {
    margin-top: 0px;
    .item {
      .Overlay {
        height: 55px;
        font-size: $h2-font-size
        span {
          font-size:  $font-size-14;
        }
      }
    }
  }
  .full_width_container {
    height: 200px;
    margin-bottom: 30px;
    a {
      margin: 0 auto;
      &.btn_white {
        font-size: $font-size-16;
        box-shadow: 0px 1px 2px rgb(0 114 81 / 25%);
        border-radius: 4px;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .design_palette {
    .top-content {
      .img-container {
        .text-content {
          .div-text {
            width: 100%;
            .text {
              font-weight: 400;
              font-size: $heading-6;
              line-height: 36px;
            }
          }
        }
      }
    }
    .brand-info {
      width: 40%;
      left: 30%;
      margin-top: -35px;
      .heading {
        font-size: $heading-3;
      }
      .desc {
        font-size: $h1-font-size
      }
    }
    .main-content {
      margin-top: 50px;
      .section {
        .ptop50 {
          padding-top: 20px;
        }
      }
      .pbot50 {
        padding-bottom: 25px;
      }
    }
  }
  .plr40px {
    padding: 15px 35px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .full_width_container {
    height: 300px;
    a {
      .btn_white {
        font-size: $h2-font-size;
        border-radius: 5px;
        text-align: center;
      }
    }
  }
  .design_palette {
    .main-content {
      .section {
        .div-content {
          .div-text {
            padding-right: 50px;
            .heading {
              font-size: $heading-5;
            }
            .text {
              font-size: $h2-font-size;
            }
          }
          .div-img + .div-text {
            padding-left: 50px ;
            padding-right: 0 ;
          }
          
          &.other_palletes {
            >div{
            .heading {
              font-size: $h1-font-size;
            }
          }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .design_palette {
    .main-content .section .div-content {
      width: calc(100% - 60px);
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}

.img_12 {
  background: white;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .design_palette .alice-carousel{
    width: 90%;
  }
}

body {
  overflow: visible !important;
}
.cp {
  cursor: pointer;
} 
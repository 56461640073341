@import '../../styles/base';
.static_page_wrapper {
  background-color: #f5f5f5;
  margin-top: 0px;
  .top_banner {
    text-align: center;
    padding-top: 84px;
    display: flex;
    flex-flow: column;
    height: inherit;
    .identity {
      height: 60%;
      box-sizing: border-box;
      color: $white;
      &:first-child {
        height: 40%;
      }
      h1 {
        font-size: $heading-3;
        font-weight: 400;
        line-height: 56px;
        margin-bottom: 10px;
      }
      .country_name {
        color: #f0e2c0;
        font-size: $heading-3;
        font-weight: 400;
        line-height: 40px;
        margin-top: 0;
      }
    }
    .page_title {
      font-size: $heading-2;
      color: $white;
      font-weight: 400;
      span {
        font-weight: 600;
      }
    }
  }
  .react-parallax {
    img {
      height: 120% !important;
      min-width: 100%;
    }
  }
  .page_details {
    margin-top: 80px;
    display: flex;
    --bg-overlay-color: rgb(36, 36, 36);
    --fill-layer-image-opacity: 0.25;
    background-color: $white;
    position: relative;
    &:after {
      position: absolute;
      content: '';
      display: block;
      height: 100%;
      width: 20%;
      background-color: rgba($white ,70%);
    }
    &.flex_end {
      justify-content: flex-end;
      background-repeat: no-repeat;
      background-size: 20% 100%;
      background-position: left top;  
      &:after{    
      left: 0;
      }
    }
    &.flex_start {
      justify-content: flex-start;
      background-repeat: no-repeat;
      background-size: 20% auto;
      background-position: right top;
      &:after{    
        right: 0;
        }
    }
    
    .content_box {
      padding: 20px;
      h3 {
        font-size: $heading-3;
        margin-top: 0;
        font-weight: 400;
      }
      p {
        font-size: $h2-font-size;
        font-weight: 600;
        color: $color-text-dark;
        line-height: 1.4;
      }
    }
    .col_1 {
      width: 80%;
      padding: 150px 0 10px 30px;
      box-sizing: border-box;
    }
    .col_2 {
      width: 20%;
    }
    .bg_animate {
      opacity: var(--fill-layer-image-opacity);
      img {
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
      }
    }
    .img_animate {
      --bg-overlay-color: $white;
      background-color: $white;
      img {
        max-width: 100%;
      }
    }
    &:last-child{
      padding-bottom: 50px;
    }
  }
}
.close_page {
  position: fixed;
  border-radius: 99em;
  width: 40px;
  height: 40px;
  box-shadow: 0 0 10px rgba($black, 50%);
  right: 10px;
  top: 70px;
  font-size: $h2-font-size;
  background: $color-primary;
  line-height: 42px;
  color: #ccc;
  z-index: 11;
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .static_page_wrapper {
    .page_details {
      .col_1,
      .col_2 {
        padding-top: 50px;
      }
      .content_box {
        h3 {          
          font-size: $heading-5;
        }
        p {
          font-size: $h1-font-size;
          font-weight: 400;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .close_page {
    display: block !important;
  }
  .static_page_wrapper {
    margin-top: 0px;
    .top_banner {
      .page_title {
        font-size: $heading-3;
      }
      .identity{
        h1{
          font-size: $heading-6;
        }
        .country_name{
          font-size: $h1-font-size;
        }
      }
    }
    .page_details {
      background-image: none !important;
      margin-top: 50px;
      &::after{
        display: none;
      }
      .col_1,
      .col_2 {
        width: 100%;
        padding-right: 30px;
        padding-top: 50px;
        background: none;
        box-sizing: border-box;
      }
      .content_box {
        padding: 0px;
        h3 {          
          font-size: $heading-6;
          line-height: 1.4;
        }
        p {
          font-size: $font-size-16;
          line-height: 1.5;
          font-weight: 400;
        }
      }
    }
  }
}

body.modalOpen::-webkit-scrollbar, body.modalOpen2::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body.modalOpen, body.modalOpen2{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
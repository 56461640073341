@import '~pjm-dls/dist/styles/base/variables';
.sector-dropdown {
  display: inline-block;
  margin: 0px 0px 0px 12px;
  vertical-align: middle;
  max-width: 285px;
  .react-dropdown-select{
    background: $white;
    border: 1px solid $color-text-dark !important;
    box-sizing: border-box !important;
    border-radius: 4px!important;
    min-width:250px !important;
    outline: 0;
    box-shadow: none !important;
    padding: 6px 10px;
  
    .react-dropdown-select-dropdown-handle svg {
      width: 26px;
      height: 24px;
      color: $color-text-dark;
    }

    .react-dropdown-select-dropdown-position-bottom {
      top:32px !important;
    }
  
    .react-dropdown-select-input{
      font-size: $font-size-14;
      line-height: 18px;
      align-items: center;
      color: $color-text-dark;
      width: 100%;
    }
    .react-dropdown-select-content{
      font-size:  $font-size-14;
      line-height: 18px;
      align-items: center;
      color: $color-text-dark;
      width: 90%;
      span{
        display: block;
        overflow-x: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
      }
    }
    .react-dropdown-select-no-data{
      text-align: left;
      background: #f6f8f6;
      font-size:  $font-size-14;
      line-height: 18px;
      color: #2B3836;
    }
    .react-dropdown-select-item{
      background: #f6f8f6 !important;
      font-size:  $font-size-14;
      line-height: 18px;
      align-items: center;
      color: #2B3836;
      border-bottom: 1px solid #f6f8f6;
    }
    .react-dropdown-select-item:hover{
      background: #ECF5F1!important;
    }
    .react-dropdown-select-item-selected{
      font-size:  $font-size-14;
      line-height: 18px;
      align-items: center;
      color: #2B3836 !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .sector-dropdown {
    display: inline-block;
    margin: 10px 0px;
  }
}
@font-face {
  font-family: 'Futura';
  src: url('./assets/font/webfont/Futura.woff2') format('woff2'),
    url('./assets/font/webfont/Futura.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura Hv BT';
  src: url('./assets/font/webfont/FuturaBT-Heavy.woff2') format('woff2'),
    url('./assets/font/webfont/FuturaBT-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura Bk BT';
  src: url('./assets/font/webfont/FuturaBT-Book.woff2') format('woff2'),
    url('./assets/font/webfont/FuturaBT-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura Md BT';
  src: url('./assets/font/webfont/FuturaBT-Medium.woff2') format('woff2'),
    url('./assets/font/webfont/FuturaBT-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura LT';
  src: url('./assets/font/webfont/futura-pt-n4') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Lt BT';
  src: url('./assets/font/webfont/FuturaBT-Light.woff2') format('woff2'),
    url('./assets/font/webfont/FuturaBT-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura BdCn BT';
  src: url('./assets/font/webfont/FuturaBT-BoldCondensed.woff2') format('woff2'),
    url('./assets/font/webfont/FuturaBT-BoldCondensed.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'calibre-B';
  src: url('./assets/font/calibre-r/calibre-r-web-bold.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'calibre';
  src: url('./assets/font/calibre-r/calibre-r-web-regular.woff2') format('woff2');
}
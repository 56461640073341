@import '../../styles/base';
.thank-you-title {
    font-size: $heading-5; 
    color: $color-text-dark;
    margin-bottom: 47px;
    margin-top: 56px;
    display: flex;
    align-items: center;
    span {
        margin-left: 15px;
        vertical-align: top;
    }
}
.thank-you-description {
    font-size: $h4-font-size;
    line-height: 30px;
    color: $color-text-dark;
    margin-bottom: 8px;
}
.charcoal {
    color: $color-text-dark;
}
.thankyou-detail {
    margin-top: 30px;
}
.thankyou-detail-row {    
    label {
        display: block;
        font-weight: 400;
        font-size: $h4-font-size;
        color: $color-text-dark;
        margin-top: 24px;
        margin-bottom: 9px;
        span {
            color: $color-error-main;
        }
    }
    .input {
        background: $white;
        border: 1px solid $color-text-dark;
        box-sizing: border-box;
        border-radius: 4px;
        height: 48px;
        width: 30%;
        font-size: $h5-font-size;
        color: $color-text-dark;
        padding: 0px 16px;
    }
    .input[name=cbreProfEmail]
    {
        width: 22%;
    }
    .domain {
        padding-left: 5px;
        color: $color-text-dark;
    }
    .invalid {
        border: 1px solid $color-error-main;
    }
    .errorMsg {
        font-size: $h6-font-size;
        line-height: 18px;
        color: $color-error-main;
        margin-top:9px;
    }
    *:focus {
        outline: none;
    }
}

@media screen and (max-width: 500px) {
    .img-check{
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: -30px;
        width: 32px;
        margin-bottom: 15px;
    } 
    .thank-you-title {   
        margin-bottom: 10px;
        margin-top: -85px;
        flex-direction: column;
        span {
            margin-top: -5px;
            font-size: 20px;
            line-height: 38px;
            text-align: center;
            display: block;
            color: $color-text-dark;
            margin-bottom: 0px;
            margin-left: 0;
        }
    .description-title {
        font-size: $h5-font-size;
        padding-top: 10px;
        margin-top: 0;
        margin-left: 0;
    }
}
   .thank-you-description {
        text-align: center;
        font-size: $h5-font-size;
   }
    .thankyou-detail-row {
        .input {
            width: 100%;
     }
        .input[name=cbreProfEmail] {
            width: 60%;
      }
      label {
        font-size: 13px;
      }
    }

}
input:invalid {
    box-shadow:none;
}
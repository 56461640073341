@import '~pjm-dls/dist/styles/base/variables';
@import '../../../styles/base';

.hc-container {
    font-size: $h4-font-size;
    line-height: 22px;
    color: $color-text-dark;
    margin-top: 30px;
    font-weight: 400;
    .headcount-comp {
        display:inline-block;
        width: 200px;
        border : 1px solid $color-text-dark;
        border-radius: 4px;
        margin: 0px 14px;
        .headcount {
            display:inline-block;
            height: 36px;
            width: 136px;
            font-style: normal;
            font-weight: 500;
            font-size:  $font-size-14;
            line-height: 18px;
            text-align: center;
            align-items: center;
            color: $color-text-dark;
            padding: 0px;
            margin: 0px;
            border: none;
        }
        .decrease {
            display:inline-block;
            width: 32px;
            height: 36px;
            color: $color-primary;
            background: $section-bg-color-1;
            border-radius: 4px 0px 0px 4px;
            padding: 0px;
            margin: 0px;
            img {
                margin-bottom:4px;
            }
            .disabled {
                opacity: 0.4;
            }
        }
        .increase {    
            display:inline-block;
            width: 32px;
            height: 36px;
            color: $color-primary;
            background: $section-bg-color-1;
            border-radius: 0px 4px 4px 0px;
            padding: 0px;
            margin: 0px;
            .disabled {
                opacity: 0.4;
            }
        }
        
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
        *:focus {
            outline: none;
        }
        input:focus::placeholder {
            color: transparent;
        }
        ::-ms-clear {
            display: none;
        }
    }
}
.hc-hint {
    display: block;
    font-size: $h6-font-size;
    color: $color-text-dark;
    margin-top: 10px;
}
.headcount-message{
    font-size:  $font-size-12;
    line-height: 17px;
    color: $color-error-main;
    margin-left:10%;
    margin-top:12px;
}
.lang_es-ES .quiz-container .question-container .hc-container .headcount-comp {
    width: 220px;
    .headcount {
        width: 156px;
    }
}

@media screen and (max-width: 500px) {
    .question-container {
        background: #F9F9F9;
        border-radius: 5px;
        padding: 0px 5px;
        margin-top: 15px;
        .hc-container {
            display:block;
            width:100%;
            font-size:  $font-size-14;
            line-height: 21px;
            margin-top: 20px;
            .headcount-comp {
                display: inline-block;
                margin: 10px;
            }
        }
        .hc-hint {
            font-size:  $font-size-12;
            margin-top: 10px;
        }
    }
}
@import '../../../styles/base';
.label-confirmation-active .MuiTypography-body1{
    font-style: normal;
    line-height: 22px;
    color: $color-text-dark;
    margin-left:10px;
}
.label-confirmation-inactive .MuiTypography-body1 {
    font-size: $h4-font-size;
    line-height: 24px;
    font-feature-settings: 'ordn' on;
    color: $color-text-dark;
    margin-left: 10px;
  }
  .title-confirmation {
    margin-top: 40px;
    font-style: normal;
    font-size: $heading-5;
    line-height: 31px;
    font-feature-settings: 'ordn' on;
    color: $color-primary;
    margin-bottom:34px;
}
  .mar {
      margin-top: 10px;
  }

.label-confirmation-active .selected-img {
    margin: 8px;
 }
 .label-confirmation-inactive .MuiIconButton-label{
    .MuiSvgIcon-root {
        border-radius: 50%;
        border: 1px solid $black;
        width: 0.6em;
        height: 0.6em;
    }
 }
 .label-confirmation-inactive .MuiRadio-root {
    color: $white;
}

 .sentence-act {
    font-style: normal;
    font-size: $h4-font-size;
    line-height: 22px;
    color: $color-text-dark;
    margin-top: 5px;
    margin-right: 20px;
    min-width: 85px;
 }
 .sentence-width{
  width: 110px;
 }

.my-heading {
    vertical-align: top;
    margin-left: 15px;
}

.description{
    font-size: $h4-font-size;
    line-height: 24px;
    color: $color-text-dark;
    margin-bottom:4px;
}

.desc-margin {
    margin-bottom: 45px!important;
}

.tnc-margin {
    margin-top: 68px;
}
.share-data{
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    .share-desc{
        font-style: normal;
        font-size: $h4-font-size;
        line-height: 22px;
        color: $color-text-dark;
        margin-left: 35px;
        white-space: nowrap;
    }
    .share {
        font-size: $h4-font-size;
        line-height: 24px;
        color: $color-text-dark;
        margin-left: 35px;
    }
    .confirm{
        display: flex;
        flex-flow: row nowrap;

        .line-dot {
            font-size: $h4-font-size;
            line-height: 24px;
            color: $color-text-dark;
            margin-top: 10px;
        }
        .input-section {
            display: flex;
        }
        .line-dot-active {
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            color: $color-text-dark;
            margin-top: 10px;
        }
        
        .input-sec {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            margin-left: 10px;
            position: relative;
            top: -10px;
            input{
                display: flex;
                padding: 0px 16px;
                width: 300px;
                height: 48px;
                background: $white;                
                box-sizing: border-box;
                color: $color-text-dark;                
                border-radius: 4px;
                font-size: $font-size-14;
                line-height: 18px;
                align-items: center;
                outline:none;
                &.email-box {                    
                    border: 1px solid $color-error-main;
                }
                &.email-box-active {                    
                    border: 1px solid $color-text-dark;
                }
                &:disabled {                    
                color: rgba($color-text-dark, 60%); 
                border-color: rgba($color-text-dark, 60%);
                }
            }
            .warn {
                font-size: $h6-font-size;
                line-height: 18px;
                color: $color-error-main;
                margin-left: 16px;
            }
            
        }
    }
    input:focus::placeholder {
        color: transparent;
    } 
}

.main-tnc{
    display: flex;
    flex-flow: row nowrap;
    
    .tnc-icon {
        cursor: pointer;
        .icons {
            width: 18px;
            height: 18px;
        }
    }

    .tnc-container{
        margin-left: 15px;
        .tnc-first, .tnc-sec{
            font-style: italic;
            font-size: $h5-font-size;
            line-height: 18px;
            align-items: center;
            color: $color-text-dark;
            .hyper-links{
                color:$color-primary;
                display: inline-block;
                margin-left: 5px;
            }
        }
        
        .tnc-third {
            font-style: italic;
            font-size: $h5-font-size;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: $color-text-dark;
            margin-top: 10px;
            margin-bottom: 24px;
        }
        
        .tnc-forth{
            font-style: italic;
            font-size: $h5-font-size;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: $color-text-dark;
        }
    }
}

@media screen and (max-width: 1350px) {
        .option-container {
           
            .share-data{
                display: flex;
                flex-flow: column wrap;
                .share-desc{
                    white-space: normal;
                }
                .input-sec{
                    position: relative;
                    margin-top: 10px;
                    margin-left: 30px;
                }
                .line-dot{
                    position: relative;
                    margin-top: 10px;
                }
            }
        }
        .lang_fr-FR .quiz-container .ques-sec .sentence-act {
            min-width: 110px;
        }
  }

  @media screen and (max-width: 500px) { 
    .lang_es-ES .share-data .confirm .input-sec .warn {
        margin-left: 5px;
    }
    .option-container {
     margin-top: 10px;
    } 
    .label-confirmation-inactive .MuiTypography-body1 {
        font-size: $h4-font-size;
        line-height: 18px;
      }
      .label-confirmation-active .MuiTypography-body1{
        font-size: $h4-font-size;
        line-height: 18px;
        font-weight: normal;
    }
    .sentence-act {
      margin-left: 10px; 
      font-size: $h4-font-size;
      line-height: 18px;
      margin-top: -20px;
      font-weight: normal;
      min-width: 85px;
    }
    .description{
      font-size: $h5-font-size;
      line-height: 18px;
      margin-bottom:10px;
      text-align:left;
      color: $color-text-dark;
      font-weight: normal;
}
    .title-confirmation {
        text-align: center;
        margin-top:26px; 
        margin-left: 0.3%;
        margin-right: 0.3%;
        line-height: 25px;
        font-size: $h3-font-size;
        font-weight: normal;
        display:block;
        .img-check-confrimation{
            display: block;
            margin-left: auto;
            margin-right: auto;
        }  
    } 
     .my-heading {
         overflow:hidden; 
         display:block;
         align-content:'center';
         margin-top:5px;
         margin-bottom: -10px;
}
    .share-data {
        .input-sec{
            .email-box, .email-box-active{
                width:230px !important;
            }
        }
        .share-desc{
            font-size: $h5-font-size;
            line-height: 22px;
            font-weight: normal;
            white-space: normal;
        }
        .share {
            font-size: $h4-font-size;
            line-height: 22px;
            margin-top: 8px;
        }
    }
    
    .main-tnc {
           margin-top: -1px;
         .tnc-container{ 
            .tnc-first, .tnc-forth, .tnc-sec, .tnc-third {
            font-size: $h6-font-size;
         }
       }
    }
  }
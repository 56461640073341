@import '../../styles/base';
.quiz-header {
  box-shadow: 0px 4px 4px rgba(242, 240, 232, 0.5);
  width: 80%;
  padding: 0 10%;
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  .header-logo {
    cursor: pointer;
    .close-btn:hover {
      font-weight: 600;
    }
    .progress-container {
      position: absolute;
      right: 17%;
      float: right;
      margin-top:-36px;
    }
  }
  .close-btn {
    position: absolute;
    right: 8%;
    top: 5px;
    float: right;
    font-size: 28px;
    color: $color-primary;
    background: $white;
    outline: none;
  }
}
.quiz-container {
  margin: 2% 10% 120px 10%;
  min-height: 300px;
  .quiz-heading {
    font-size: $heading-5;
    line-height: 36px;
    font-feature-settings: "ordn" on;
    color: $color-text-dark;
    font-weight: 500;
    margin-bottom: 0.5em;
  }
}
.quiz-welcome-msg {
    font-size: $h4-font-size;
    line-height: 24px;
    text-align: center;
    color: $color-primary;
    margin-right: 10px;
    margin-top: 50px;
    width: 100%;
    .div-firstline, .div-secondline{
      display: inline-block;
      margin-right: 5px;
    }
}
.icon-success {
    background: url("../../assets/icon/success_badge.png") no-repeat center center;
    margin-right: 10px;
    position: relative;
    top: 1px;
    background-size: 100%;
}


@media screen and (max-width: 500px) { 
  .quiz-header {
    height: 60px;
    box-shadow: 0px 4px 4px rgba(242, 240, 232, 0.5); 
    flex-flow: column;
    width: calc(100% - 60px);
    padding-left: 30px;
    padding-right: 30px;
    .header-logo {
      width: 100%;
      max-width: none;
      height: 60px;
      display: inline-flex;
      padding: 23px 0 22px;
      align-items: initial;      
    }
    .progress-container {
      position: initial;
      margin: 30px auto;
      float: left;     
    }
    .close-btn {      
      top: 17px;
    }
  }
  .quiz-container {
    margin-top: 130px;
    min-height: 100px;
    margin-left: 30px;
    margin-right: 30px;
    .question-container{
      width: calc(100% - 20px);
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 10px;
    }
    .quiz-heading {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 15px;
      font-weight: normal;
    }
  }
  .quiz-welcome-msg {
    text-align: center;
    width: 100%;
    font-size:  $font-size-14;
    line-height: 21px;
    margin-top: 25px;
    .div-firstline {
      display: block;
      margin-bottom: 10px;
    }
    .div-secondline {
      display: block;
    }
  }
}
@use "@material/button/styles";
@use "@material/button";
@use "@material/fab";
@use "~pjm-dls/dist/emerald/variables" as variables;
@use "~pjm-dls/dist/emerald/mixins" as mixins;


@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import "~pjm-dls/dist/emerald/components";
@import "~pjm-dls/dist/emerald/variables";
@import "~pjm-dls/dist/emerald/mixins";
@import "~pjm-dls/dist/styles/base/variables";

$section-bg-color-1:#EFF3F3;
$graph-secondary-color: #DADADA;
$text-color-gray-light:#272B2F;
$heading-2:3.75rem;
$heading-3:3rem;
$heading-4:2.5rem;
$heading-5:2.125rem;
$heading-6:1.875rem;


body {
    margin: 0;
}
body, code select,
input,
button {
    font-family: "calibre", sans-serif !important; //$font-family-base;
    -webkit-font-smoothing: antialiased;
}
::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background: #98a9a7;
    border-radius: 6px;
    min-height: 50px;
  }
  .d-flex{
    display: flex !important;
  }
  .flex_grow{
    flex: 1 1;
    
  }

  .custom-text-button,
    .custom-outlined-button {
        font-family: variables.$button-font;
        // @include customButton.text-outlined-button-themes(<outline-color>, <ink-color>)
        // <outline-color> : border/outline color of the button
        // <ink-color> : text color of the button
        &.primary {
            @include mixins.text-outlined-button-themes(
                variables.$button-primary-border,
                variables.$button-primary-color
            );
        }
        &.none {
            @include mixins.text-outlined-button-themes(variables.$outline-color, variables.$no-color);
        }
        &.accent {
            @include mixins.text-outlined-button-themes(variables.$outline-color, variables.$button-accent-color);
        }
        &.warn {
            @include mixins.text-outlined-button-themes(variables.$outline-color, variables.$button-warn-color);
        }
    }

    .custom-flat-button,
    .custom-raised-button,
    .custom-fab-mini,
    .custom-fab-raised {
        font-family: variables.$button-font;
        // @include customButton.flat-raised-button-themes(<container-fill-color>, <ink-color>)
        // <container-fill-color> : container fill color of the button
        // <ink-color> : text color of the button
        &.primary {
            @include mixins.flat-raised-button-themes(variables.$primary-color, variables.$white-color);
        }
        &.none {
            @include mixins.flat-raised-button-themes(variables.$white-color, variables.$no-color);
        }
        &.accent {
            @include mixins.flat-raised-button-themes(variables.$button-accent-color, variables.$white-color);
        }
        &.warn {
            @include mixins.flat-raised-button-themes(variables.$button-warn-color, variables.$white-color);
        }
    }

    .custom-flat-button,
    .custom-raised-button {
        &.primary {
            &:hover {
                background: variables.$primary-button-hover-background;
            }
            &:focus {
                outline: variables.$focused-flat-button-border;
                border-radius: variables.$focused-button-border-radius;
            }
        }
    }

    .custom-text-button,
    .custom-outlined-button {
        &.primary:not(.custom-fab-circle){
            &:hover {
                background: variables.$outlined-text-button-hover-background;
            }
            &:focus {
                outline: variables.$focused-other-button-border;
                border-radius: variables.$focused-button-border-radius;
            }
        }
    }

    .custom-fab-mini,
    .custom-fab-raised {
        &.disable {
            @include mixins.disable-fab(variables.$disabled-button-container-color);
        }
    }
    .mdc-button {
        letter-spacing: 0;
        font-size: variables.$button-font-size;
        text-transform: none !important;
        @include button.ripple-states(variables.$primary-color);
        padding: variables.$button-default-padding;
        &.small {
            padding: variables.$button-small-padding;
            height: variables.$button-small-height;
        }
    }
    .light {
        background: variables.$button-light-background;
        &:hover {
            background: variables.$light-button-hover-background;
        }
        &:focus {
            outline: variables.$focused-other-button-border;
            border-radius: variables.$focused-button-border-radius;
        }
    }
    .mdc-button--icon-leading .material-icons {
        padding-right: variables.$button-text-width-icon-padding;
    }
    .mdc-button--icon-trailing .material-icons {
        padding-left: variables.$button-text-width-icon-padding;
    }
    .small .material-icons {
        font-size: variables.$button-small-icon-size;
    }
    .custom-fab-circle {
        border-radius: variables.$button-circle-border-radius;
        height: variables.$button-circle-height;
        width: variables.$button-circle-width;
        min-width: variables.$button-circle-width;
    }


    .mdc-button:not(:last-child), .mdc-button:not(:last-child){
        margin-right: 16px !important;
        margin-left: 0 !important;
      }
.d-flex{
    display: flex !important;
}
.d-inline-flex {
    display: inline-flex !important;
}
.align-items-center {
    align-items: center !important;
}

  :export {
    primary: $color-primary;
    colorTextDark: $color-text-dark;
    colorTextDark2: $color-text-dark2;
    colorTextDarkDisabled: $color-text-dark-disabled;
  
    colorErrorMain: $color-error-main;
  }

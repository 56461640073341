@import '~pjm-dls/dist/styles/base/variables';
.dropdown {
    position: relative;    
    &.open>.dropdown-menu {
      display: block;
    }
    .dropdown-toggle {
      display: flex;
      padding: 0px 6px 2px 0px;
      margin-bottom: 0;
      font-weight: normal;
      font-size: $h5-font-size;
      line-height: 17px;
      text-align: left;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      vertical-align: middle;
      cursor: pointer;
      color: $color-primary;
      border-bottom: 1px solid $color-primary;
      min-width: 120px;
      @media screen and (max-width:540px) {
        min-width: 100px;
      }
      .countryFlags {
        padding-left : 36px;
        padding-top: 1px;
      }
      .caret {        
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 10px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid\9;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
    }
    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0px;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 140px;
      padding: 10px;
      margin: 2px 0 0;
      font-size:  $font-size-14;
      text-align: left;
      background-color: #c3d4cf;
      @media screen and (max-width:540px) {
        font-size: $font-size-12;
      }
      .countryOptions {
        display: inline-block;
        width: 100%;
        vertical-align: top;
        max-height: 125px;
        overflow: overlay;
        .countryTitle {
          font-size:  $font-size-16;
          padding-bottom: 5px;
          border-bottom: 1px solid #adadad;
        }
        .option {
          display: block;
          margin-top: 10px;
          cursor: pointer;
          img {
            width: 16px;
            height: 16px;
          }
          div {
            display: inline-block;
            margin-left: 6px;
            vertical-align: text-bottom;
          }
        }
        .selected{
          padding-bottom: 7px;
          border-bottom: 1px solid #adadad;
        }
      }
    }  
  }
  .countryFlags {
    background-image: url('../../../assets/country_flags.png');
    background-repeat: no-repeat;
    background-position: 0px 2px;
    padding-left: 32px;
    &.US {
      background-position: 0px 2px !important;
    }
    &.ES {
      background-position: 0px -22px !important;
    }
    &.SG {
      background-position: 0px -51px !important;
    }
    &.UK {
      background-position: 0px -78px !important;
    }
    &.FR {
      background-position: 0px -107px !important;
    }
  }
  .lang_es-ES {
    .dropdown {
      .dropdown-menu{
        left: auto;
        right: 0;
      }
    }
  }
  .footer .dropdown {
    max-width: 150px;
  }

@import '~pjm-dls/dist/styles/base/variables';
.countrymodal {
    position: fixed; 
    z-index: 500;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: hidden; 
    background-color: rgba(0,0,0,0.9);
    background-position: center top;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .modal-content {
      position: absolute;
      background-color: #fefefe;
      padding: 20px;
      border: 1px solid #888;
      width: 45%; 
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      max-height: 80%;
      overflow-y: auto;
      max-width: 300px;
      border-radius: 10px;
      h1{
        margin-top: 0;
      }
  .dropdown .dropdown-toggle{
    display: inline-flex;
    width: 90%;
    justify-content: space-between;
  }
  select{
    width: 80%;
    padding: 10px 10px;
    border-radius: 5px;
    font-size:  $font-size-14;
    line-height: 18px;
    align-items: center;
    color: $color-text-dark;
    border:1px solid #D9E1E0 !important;
    outline: 0;
    appearance:none;
    background: url(../../../assets/icon/down.png);
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 15px;
  }
  
      .popup-heading{       
        .heading {
          display:inline-block;
          font-style: normal;
          font-size: $h1-font-size;
          line-height: 24px;
          color: $color-text-dark;
          width: 100%;
        }
      }  
      .spacing {
        border-bottom: 1px solid #D9E1E0;
        padding-bottom: 40px;
      }
  
      .popup-btn-container {
        text-align: right;
        vertical-align: middle;    
        border-top: solid #ccc 1px;
        margin-top: 50px;
        .btn {
            padding: 12px 20px;        
            box-shadow: 0px 1px 2px rgba(0, 114, 81, 0.25);
            border-radius: 7px;
            margin-top:20px;
            margin-right:20px;
        }
        .btn-save {
          padding: 12px 20px;        
          box-shadow: 0px 1px 2px rgba(0, 114, 81, 0.25);
          border-radius: 7px;
          margin-top:20px;
          font-style: normal;
          font-size:  $font-size-16;
          line-height: 20px;       
          color: $white;
          background: #006747;
          margin-right: 0;
          &:disabled {
            background: #BFD9D1;
            opacity: 0.5;
        }
      }
       
       
      }
    }
  } 
  
  @media screen and (max-width: 540px) { 
    .countrymodal .modal-content {
      width: 80%;
      border-radius: 10px;
      .popup-btn-container {
        display: flex;
        justify-content: flex-end;  
        .continue-quiz {
          font-size:  $font-size-14;
        }
      }
      .popup-heading {
        .heading {
        font-size: 18px;
        font-weight: normal;
        }
      }
    }
  }
@import '../../styles/base';

.home {
  margin-top: 80px;
  width: 100%;
  height: 100%;
  position: relative;
  .top-content {
    background: rgba(44, 44, 44, 0.75);
    .img-container {
      background-image: url("../../assets/home_img.jpg");
      background-size: cover;
      background-position: center;
      width: 100%;
      .text-content {
        text-align: left;
        padding: 3% 5% 18% 5%;
        .div-text {
          width: 85%;
          display: inline-block;
          vertical-align: top;
          .text {
            font-weight: 600;
            font-size: $h1-font-size;
            line-height: 40px;
            background: $white;
            color:$color-primary;
            width:fit-content;
            padding: 0px 10px;
            &+.text {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
  .brand-info {
    position: absolute;
    margin-top: -65px;
    background: $white;
    color: $color-text-dark;
    text-align: center;
    width: 30%;
    left: 35%;
    font-weight: 400;
    border-radius: 2px;
    padding: 15px 0;
    box-shadow: 0px 0px 17px rgba($black, 20%);

    .heading {
      font-size: $heading-2;
      line-height: 1;
      margin-bottom: 10px;
    }
    .desc {
      font-size: $h1-font-size;
      font-weight: 600;
    }
  }
  .main-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 85px;
    .bgwhite {
      background: $white;
    }
    .bggrey {
      background: #F0EFED;
    }
    .bggreen {
      background: #E8F1EE;
    }
    .bglightGrey {
      background: #fbfbfb;
      border-bottom: solid 1px #e9e9e9;
    }
    .bgEmrald {
      background-color: $section-bg-color-1;
    }
    .pbot50 {
      padding-bottom: 50px;
    }
    .ptop50 {
      padding-top: 50px;
    }
    .section {
      width: 100%;
      display: flex;
      .div-content {
        width: 60%;
        margin: 36px auto;
        .div-text-center {
          width: 70%;
          margin: 0px auto;
          text-align: center;
          .heading {
            font-size: $heading-5;
            line-height: 41px;
            color: $color-text-dark;
            font-weight: 400;
          }
          .text {
            font-weight: 400;
            font-size:  $font-size-16;
            color: $color-text-dark;
            line-height: 28px;
          }
        }
        .div-img { 
          width: 38%;
          display: inline-flex;
          text-align: center;
          img {
            width: 100%;
          }
        }
        .div-text {
          width: 58%;
          display: inline-flex;
          vertical-align: top;
          flex-direction: column;
          .heading {
            font-size: $heading-5;
            line-height: 41px;
            color: $color-text-dark;
            font-weight: 400;
            margin: 10px 50px;
          }
          .text {
            font-size:  $font-size-16;
            line-height: 30px;
            color: $color-text-dark;
            padding: 10px 50px;
          }
        }
        .div-imgs {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 30px;
          .img {
            display: inline-flex;
            width: 15%;
            background: $color-background3;
            border-radius: 4px;
            padding: 8px;
            box-sizing: border-box;
            cursor: pointer;
            img {
              border: 1px solid $color-background2;
              width: 100%;
              box-sizing: border-box;
            }
          }
          .selected {
            background: $color-primary;
          }
        }
      }
    }
  }
}
.btnGetStarted {
  font-family:$button-font;
  font-size: $button-font-size;
  background: $primary-color;
  color: $white;
  padding: $button-default-padding;
}
.btnGetStarted:disabled {
  opacity: 0.6;
}
.plr40px {
  padding: 20px 50px;
}

.lang_en-SG{
  .div-imgs {
    .img {
      cursor: auto !important;
    }
  }
}
.disabled_container {
  opacity: 0.4;
}
.pos_rel {
  position: relative;
}
.pad_btm1{
  padding-bottom: 1px;
}
.max_wd100 {
  img {
    max-width: 100%;
  }
}
.mobile_view{
  display: none;
}
.desktop_view {
  display: block;
}
.overflow_hidden {
  overflow-x: hidden;
}
.mt_m30{
  margin-top: -30px !important;
}
.Toastify__toast-container--top-right{
  top: 48px !important;
  right: 0 !important;
  width: 230px !important;
  padding: 0 !important;
}
.Toastify__toast--success{
  background-color: transparent !important;
  color: $white;
  box-shadow: none !important;
  padding: 0 !important;
  .Toastify__toast-body{
    box-shadow: 0 1px 10px 0 rgba($black, 10%), 0 2px 15px 0 rgba($black, 5%);
    background-color: $color-text-dark;
    margin: 15px 0 0;
    border-radius: 5px;
    font-size: $font-size-12;
    padding: 15px;
    font-weight: 400;
  }
 &::before {
    content: '';
    height: 0;
    width: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;  
    border-bottom: 15px solid $color-text-dark;
    position: absolute;
    top: 0px;
    left: 20px;
    }
}
.lang_es-ES {
  .Toastify__toast--success{
  &::before {
    left: 50%;
  }
}
}
.Toastify__close-button{
    top: 17px;
    right: 17px;
    position: relative;
    color: $color-accent-green;
}
@media screen and (max-width: 540px) {
  .home {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    .top-content {
      .img-container {
        .text-content {
          padding-left: 30px;
          padding-right: 30px;
          .div-text {
            width: 100%;
            .text {
              font-weight: normal;
              font-size: 18px;
              line-height: 26px;
            }
            
          }
        }
      }
    }
    .brand-info {
      width: 60%;
      left: 20%;
      margin-top: -35px;
      padding: 10px 0;
      .heading {
        font-size: $heading-6;
        margin-bottom: 5px;
      }
      .desc {
        font-size: $h5-font-size;
      }
    }
    .main-content {
      width: 100%;
      display: block;
      margin-top:50px;
      .section {
        width: 100%;
        .pbot50 {
          padding-bottom: 0px;
        }
        .ptop50 {
          padding-top: 20px;
        }
        .div-content {
          width: calc(100% - 60px);
          margin: 36px 30px;
          .div-text-center {
            width: 80%;
            .heading {
              font-size: $h2-font-size;
              line-height: 1.2;
              font-weight: normal;
              
            }
            .text {
              font-size: $h6-font-size;
              line-height: 1.4;
            }
            .mtop40 {
              margin-top: 20px;
            }
            .mtop20 {
              margin-top: 10px;
            }
          }
          .div-text {
            .heading {
              font-size: $h3-font-size;
              line-height: 1.2;
              margin-top: 5px;
              margin-bottom: 0px;
              margin-left: 0;
              margin-right: 0;
            }
            .text {
              font-size: $h6-font-size;
              line-height: 1.4;
              padding: 5px 0px;
              margin-left: 0;
            }
          }
          .div-text {
            width: 58% !important;
          }
          .div-img{
            width: calc(42% - 20px);
            margin-right: 20px;
          }
          .div-text+.div-img{
            width: calc(42% - 20px);
            margin-left: 20px;
            margin-right: 0;
          }
          .div-imgs {
            margin-top: 20px;
            .img {
              width: 30%;
              padding: 4px;
            }
            :nth-child(4),:nth-child(5),:nth-child(6) {
              margin-top: 5%;
            }
          }
          .mbot40 {
            margin-bottom: 25px;
          }
          .mtop40 {
            margin-top: 15px;
          }
        }
      }
    }    
    .mobile_view {
      display: block;
    }
    .desktop_view{display: none;}
    .next_container{
      img {
        width: 200%;
        max-width: 200%;
      }
      .what_container.mobile_view{
        margin-bottom: -15px;
        margin-top: 20px;
      &+div {
        
        img{
          margin-left: -100%;
        }
      }
    }
    }
  }
  .Toastify__toast {
    left: auto !important;
  }
  .lang_es-ES {
  .home {   
    .top-content {
      .img-container {
        .text-content {
          .div-text {
            .text {             
              font-size:  $font-size-14;
              line-height: 22px;
              padding-top:2px;
              padding-bottom: 2px;
            }            
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 480px){
  .Toastify__toast-container {
    left: auto !important;
  }
}
  .btnGetStarted {
    font-size:  $font-size-14;
    line-height: 18px;
    padding: 10px 20px;
  }
  .plr40px {
    padding: 10px 30px;
  }
}
@media screen and (max-width:540px){
  .lang_es-ES {
    .home {   
      .top-content {
        .img-container {
          .text-content {
            .div-text {
              .text {             
                font-size:  $font-size-12;
                line-height: 22px;
                padding-top:2px;
                padding-bottom: 2px;
              }            
            }
          }
        }
      }
    }
}
}
@media screen and (min-width: 541px) and (max-width: 1200px) {  
  .home {
    width: 100%;
    height: 100%;
    .top-content {
      .img-container {
        .text-content {
          .div-text {
            width: 100%;
            .text{
              font-size: $h3-font-size;
            }
          }
        }
      }
    }
    .brand-info {
      width: 50%;
      left: 25%;
      margin-top: -50px;
      .heading {
        font-size: $heading-4;
      }
      .desc {
        font-size: $h3-font-size;
      }
    }
    .main-content {
      margin-top: 50px;
      .section {
        .div-content {
          width: 75%;
          .div-text-center {
            width: 70%;
            .heading {
              font-size: $h1-font-size;
              line-height: 1.2;
            }
            .text {
              font-size: $h4-font-size;
              line-height: 1.4;
            }
            .mtop40 {
              margin-top: 20px;
            }
          }
          .div-text {
            .heading {
              font-size: $h1-font-size;
              line-height: 1.2;
            }
            .text {
              font-size: $h4-font-size;
              line-height: 1.4;
            }
          }
        }
        .ptop50 {
          padding-top: 20px;
        }
      }
      .pbot50 {
        padding-bottom: 25px;
      }
    }
  }
  .plr40px {
    padding: 15px 35px;
  }
}

@media screen and (max-width: 1023px) {
.home .main-content .section  .div-content{
  width: calc(100% - 60px);
    margin-left:30px;
    margin-right: 30px;
    .div-text{
      width: 62%;
    }
}
}
@import '~pjm-dls/dist/styles/base/variables';
.question-title {
    font-size: $h1-font-size;
    line-height: 1.4;
    font-feature-settings: 'ordn' on;
    color: $color-text-dark;
    margin-bottom:.5em;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.label-sec {
  display: flex;
  flex-flow: column nowrap;
  .bg {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 10px;
    &:before {
      border-top: 1px solid #7D7D7D;
      content:"";
      margin: 0 auto; /* this centers the line to the full width specified */
      position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
      top: 50%; left: 0; right: 0; bottom: 0;
      width: 77%;
      z-index: -1;
    }
    .MuiFormControl-root{
      width:100%
    }
    .MuiFormGroup-root{
      display:flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;
      align-content: space-around;
      .MuiFormControlLabel-root{
        margin-left: 0px;
        margin-right: 0px;
      }
      .label-active .MuiTypography-body1{
        font-size: 18px;
        line-height: 22px;
        font-feature-settings: 'ordn' on;
        color: $color-primary;
      }
      .label-active .MuiRadio-root:hover {
        color: $white;
        background-color: $white;
      }
      .label-inactive .MuiRadio-root:hover {
        color: $white;
      }
      .label-active .MuiIconButton-label{
        .MuiSvgIcon-root {
          border: 1px solid $color-primary;
          border-radius: 50%;
          color:$color-primary;  
          background-color: $white;
          width: 1.35em;
          height: 1.35em;
        }
        .PrivateRadioButtonIcon-layer-6{
          color:$color-primary;
        }
        .jss6{
          color:$color-primary;
        }
      }
      .label-inactive:hover {
        background-color:#E0EDE9;
      }
      .label-active .selected-img {
        margin: 8px;
      }
      .label-active .Mui-checked{
        color:$color-primary;
      }
      .label-inactive .MuiIconButton-label:hover{
        color: #fff
      }
      .label-inactive .MuiIconButton-label{
        .MuiSvgIcon-root {
          border-radius: 50%;
          background-color: $white;
          border: 1px solid #7D7D7D;
          width: 1.35em;
          height:1.35em;
        }
      }
      .label-inactive .MuiRadio-root {
        color: $white;
      }
      .label-inactive .MuiTypography-body1 {
        font-size: 18px;
        line-height: 24px;
        font-feature-settings: 'ordn' on;
        color: $color-text-dark;
      }
      .option-div {
        display:block;
        margin-bottom:10px;
        .option-text {
          font-size: 18px;
          line-height: 24px;
          font-feature-settings: 'ordn' on;
          color: $color-text-dark;
          margin-left:16px;
        }
      }
    }
  }
  .labels{
    display:flex;
    flex-flow:  row nowrap;
    justify-content: space-around;
    align-content:center;
    align-items: space-around;
    .lables-active {
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      font-feature-settings: 'ordn' on;
      color: $color-primary;
      margin: 0 10px;
      flex: 1;
      width: 160px;
    }
    .lables-inactive {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      font-feature-settings: 'ordn' on;
      color: #7D7D7D;
      margin: 0  10px;
      flex:  1;
      width: 160px;
    }
  }
}
.ques-sec {
  display:flex;
  flex-flow: row nowrap;
  .sentence {
    margin-top: 5px;
    vertical-align: top;
    font-size: $h4-font-size;
    min-width: 85px;
    line-height: 24px;
    color: $color-text-dark;
  }
  .adjust {
    margin-top: 6px;
    vertical-align: top;
    font-size: $h4-font-size;
    line-height: 24px;
    color: $color-text-dark;    
    min-width: 85px;
  }
  .adjust-active {
    margin-top: 5px;
    vertical-align: top;
    min-width: 85px;
    font-size: $h4-font-size;
    line-height: 22px;
    color: $color-text-dark;
  }
  .sentence-active {
    margin-top: 5px;
    vertical-align: top;
    font-size: $h4-font-size;
    line-height: 24px;
    color: $color-text-dark; 
    min-width: 85px;
  }
  .option-container {
    margin-left:10px;
    margin-bottom: 20px;
    vertical-align: top;
    .MuiFormControl-root{
      width:100%;
      .MuiFormControlLabel-root{
        margin-bottom: 21px;
        font-size: $h4-font-size;
        font-weight: 400;
      }
    }
    .label-active .selected-img {
      margin: 8px;
    }
    .label-active .MuiTypography-body1{
      margin-left: 10px;
      font-family: $font-family-base;
     }
    .label-active .MuiRadio-root:hover {
      color: $white;
      background-color: $white;
    }
    .label-inactive .MuiRadio-root:hover {
      color: $white;
    }
    .label-active .MuiIconButton-label{
      .MuiSvgIcon-root {
        border: 1px solid $color-primary;
        border-radius: 50%;
        color: $color-primary;
        background-color: $white;
        width: 0.6em;
        height: 0.6em;
      }
    }
    .label-inactive:hover {
      background-color:#E0EDE9;
      .MuiSvgIcon-root {
        background-color: $white !important;
      }
    }
    .label-active .Mui-checked{
      color:$color-primary;
    }
    .label-inactive .MuiIconButton-label:hover{
      color: $white;
    }
    .label-inactive .MuiIconButton-label{
      .MuiSvgIcon-root {
        border-radius: 50%;
        border: 1px solid $color-text-dark;
        width: 0.6em;
        height: 0.6em;
      }
    } 
    .label-inactive .MuiRadio-root {
      color: $white;
    }  
    .label-inactive .MuiTypography-body1 {
      font-feature-settings: 'ordn' on;
      color: $color-text-dark;
      margin-left: 10px;      
      font-family: $font-family-base;
    }
    .option-div {
      display:block;
      margin-bottom:10px;
      .option-text {
        font-size: $font-size-16;
        line-height: 24px;
        color: $color-text-dark;
        margin-left:16px;
      }
    }
    .input{
      background: $white;
      border: 1px solid #D9E1E0;
      box-sizing: border-box;
      border-radius: 7px;
      height: 40px;
      width: 200px;
      font-size:  $font-size-14;
      line-height: 18px;
      color: $color-text-dark;
      padding: 0px 16px;
      margin-left: 20px;
      margin-top: -10px;
      margin-bottom: 20px;
    }
    .required {
      border: 1px solid $color-error-main;
    }
  }
}

.radio-options {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  width: 100%;
  .radio-option {
    display: block;
    border-top: 1px solid #7D7D7D;
    text-align: center;
    width: 25%;
    .radio-img {
      width: 32px;
      height: 32px;
      margin-top: -16px;
      margin-left: -32px;
      text-align: center;
      cursor: pointer;
      .img {
        margin-top: -16px;
        position: absolute;
        width:32px;
        height:32px;
      }
    }
    .radio-text {
      display: block;
      width: 90%;
      padding: 30px 5% 0px 5%;
      font-feature-settings: 'ordn' on;
      color: $color-text-dark;
      cursor: pointer;
      font-size: $font-size-16;
      line-height: 30px;
    }
    .active {
      color: $color-primary;
      font-weight: 500;
    }
  }
  .radio-option:first-of-type {
    border-top: none;
    position: relative;
    &::before {
      width: calc(50% + 16px);
      height: 1px;
      content: ' ';
      position: absolute;
      left: 50%;
      top: 0;
      background: #7D7D7D;
      z-index: -1;
    }
  }
  .radio-option:last-of-type {
    border-top: none;
    position: relative;
    &::before {
      width: 50%;
      height: 1px;
      content: ' ';
      position: absolute;
      top: 0;
      right: 50%;
      background: #7D7D7D;
      z-index:-1;
    }
  }
}

.lang_es-ES {
  .quiz-container {
    .ques-sec {
      .adjust, .adjust-active , .sentence, .sentence-active {       
        margin-right: 20px;
      }
    }
  }
}


  
@media screen and (max-width: 500px) {
  .question-title {
    font-size:  $font-size-14;
    line-height: 18px;
    margin-bottom: 20px;
    font-weight: normal;
  }
  .ques-sec{
    display: flex;
    flex-flow: row wrap;
    .sentence, .sentence-active, .adjust, .adjust-active {
      font-size:  $font-size-14;
      width: 100% !important; 
      font-weight: normal;
    }
    .option-container {
      width: 100% !important;
      .MuiFormControl-root {
        .MuiFormControlLabel-root{
          margin-bottom: 5px;
        }
      }
      .label-active, .label-inactive {
        margin-bottom: 5px;
        .MuiTypography-body1 {
          font-size:  $font-size-14;
          line-height: 21px;
          font-weight: normal;
        }
      }
      .input{ 
        height: 32px;
        width: 175px;
      }
    }
  }
  
    .radio-options {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      text-align: left;
      .radio-option {
        display: inline-flex;
        padding-bottom: 25px;
        border-top: none;
        border-left: 1px solid #7D7D7D;
        align-items: center;
        width: 100%;
        .radio-img{
          display: inline-block;
          width: 18px;
          height: 18px;
          margin-left: -9px;
          margin-top: 0px;
          text-align: center;
          .img {
            margin-top: 0px;
            position: inherit;
            width:18px;
            height:18px;
          }
        }
        .radio-text {
          display: inline-block;
          width: 90%;
          vertical-align: middle;
          padding: 0px 0px 0px 20px;
          font-size:  $font-size-14;
          line-height: 21px;
          text-align: left;
          font-weight: normal;
        }
      }
      .radio-option:first-of-type {
        border-left: none;
        position: relative;
        &::before {
          width: 1px;
          height: calc(50% + 8px);
          content: ' ';
          position: absolute;
          bottom: 0;
          left: 0;
          top: unset;
          right:unset;
          background: #7D7D7D;
          z-index: -1;
        }
      }
      .radio-option:last-of-type {
        padding-bottom: 0px;
        border-left: none;
        position: relative;
        &::before {
          width: 1px;
          height: 50%;
          content: ' ';
          position: absolute;
          top:0;
          left:0;
          bottom:unset;
          right:unset;
          background: #7D7D7D;
          z-index:-1;
        }
      }
    }
  
}
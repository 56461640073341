@import '../../styles/base';
.quiz-footer{
    background: transparent;
    bottom: 0!important;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    vertical-align: middle;
    right: 0;
    margin-right: 10%;
    padding:20px 0px;
}
@media screen and (min-width: 501px) { 
.lang_en-SG {
    .quiz-footer {
        background-color: $white;
        width: 100%;
        margin-right: 0;
        padding: 20px 10%;
        box-sizing: border-box;
        }
    }
}
@media screen and (max-width: 500px) { 
    .quiz-footer{
        width: calc(100% - 60px);
        padding: 20px 30px;
        margin-right: 0;
        background: $section-bg-color-1;
    }
}
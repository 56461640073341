@import '~pjm-dls/dist/styles/base/variables';
@import '../../styles/base';
.content-body {
  color: $color-text-dark;
  font-size: $font-size-16;
  font-weight: 400;
  line-height: 28px;
  margin: 5% 10%;
  max-width: 80%;
  text-align: justify;
  .heading {
    margin-bottom: 23px;
    color: $color-text-dark;
    font-size: 3rem;
    line-height: 55px;
    font-weight: 400;
    text-align: center;
    padding-top: 55px !important;
  }
  .para-heading {
    text-decoration: underline;
  }
  ol.main-list {
    margin-top: 15px;
    li {
      margin: 16px 0 0 16px;
      padding-left: 16px;
      font-weight: 500;
      font-size: $h2-font-size;
      ::marker {
        font-weight: 400;        
      }
      p{
        font-weight: 400;
        margin-top: 0;
        font-size: $font-size-16;
      }
      >div{
        font-weight: 400;
        font-size: $font-size-16;
      }
      ol {
        margin:15px 0 0px;
        padding: 0;
        li {
          font-weight: 400;
          margin-bottom: 15px;          
        font-size: $font-size-16;
          .heading {
            text-decoration: underline;
          }
          ::marker {
            font-weight: 400;
          }
        }
      }
      .paragraph {
        font-weight: 400;
        p{
          strong{
            font-size: $h2-font-size;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.paragraph {
  font-weight: 400;
  p{
    strong{
      font-size: $h2-font-size;
      font-weight: 500;
    }
  }
}
.termandcondition-header {
  .header-logo {    
    a {
      text-decoration: none;
      color: $color-primary;
    }
  }
 }
 .date_section{
  font-size:  $font-size-14;
}
.copyright_txt {
  font-size: 14px !important;
}
@media screen and (max-width: 500px)  {
  .content-body {
    .heading {
      font-size: $h1-font-size;
      margin-top: 55px;
      margin-bottom: 10px;
    }
    .paragraph {
      font-size:  $font-size-14;
      line-height: 24px;
    }
    ol.main-list {
      li {
        margin: 16px 0 0 -20px;
        font-size: $h3-font-size;
        line-height: 24px;
      }
      ol {
        padding-left: 15px !important;
        li {
          font-size:  $font-size-14;
        }
      }
    }
  }
  .termandcondition-header {
    .header-logo {
      .logo {
        margin-top: 3%;
      }
    }
  }
  .date_section{
    font-size:  $font-size-12;
  }
  
.copyright_txt {
  font-size: $font-size-12 !important;
}
 }

@import '~pjm-dls/dist/styles/base/variables';
.modalpopup {
  position: fixed; 
  z-index: 500;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto;
  background-color: rgba($black,40%);
  .modal-content {
    position: relative;
    background-color: $white;
    margin: 3% auto; 
    padding: 24px;
    width: 75%;
    max-width: 600px;
    border-radius: 6px;
    /* The Close Button */
    .close {
      top: 15px;
      right: 24px;
      color: $color-text-dark;
      font-size: $h1-font-size;
      position: absolute;
      cursor: pointer;
    }
    .popup-heading{
      font-size: $h1-font-size;
      line-height: 30px;
      color: $color-text-dark;
      margin-bottom: 40px;
      font-weight: 500;
    }
    .popup-desc {      
      font-size:  $font-size-16;
      line-height: 24px;
      color:$color-text-dark;
    }
    .popup-btn-container {
      text-align: right;
      vertical-align: middle;
      margin-top: 20px;      
    }
    .modal-detail-row {
      label {
        display: block;
        font-weight: 400;
        font-size:  $font-size-16;
        line-height: 18px;
        color: $color-text-dark;
        margin-top: 24px;
        margin-bottom: 9px;
        width: 55%;
        span {
          color: $color-error-main;
        }
      }
      .input {
        background: $white;
        border: 1px solid $color-text-dark;
        box-sizing: border-box;
        border-radius: 4px;
        width: 55%;
        font-size:  $font-size-14;
        line-height: 18px;
        color: $color-text-dark;
        padding: 12px;
        height: 42px;
      }
      .domain {
          padding-left: 5px;
          color: #535F62;
      }
      .input[name=emailDesigner]
      {
          width: 40%;
      }
      input:disabled
      {
        border: 1px solid rgba($color-text-dark, 60%);
        color: rgba($color-text-dark, 60%);
      }      
      .textarea {
        background: $white;
        border: 1px solid rgba($color-text-dark, 100%);
        color: rgba($color-text-dark, 100%);
        box-sizing: border-box;
        border-radius: 4px;
        width: 55%;
        font-size:  $font-size-14;
        line-height: 18px;
        padding: 12px;
        height: 99px;
      }      
      .invalid {
        border: 1px solid $color-error-main;
      }
      .errorMsg {
        font-size:  $font-size-12;
        line-height: 18px;
        color: $color-error-main;
        margin-top:9px;
      }
      *:focus {
        outline: none;
      }     
    }
  }
}
input:invalid {
  box-shadow:none;
}

  @media screen and (max-width: 500px) { 
    .modalpopup {
      .modal-content {
        width: 80%;
        max-width: 80%;
        margin: 5% auto;
        .popup-heading{
          font-size: $font-size-16;
          line-height: 24px;
          margin-bottom: 20px;
        }
        .popup-desc {
          font-size: $font-size-14;
          line-height: 21px;
          margin-bottom: 15px;
        }
        .modal-detail-row {
          label {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 5px;
            font-size:  $font-size-14;
            line-height: 15px;
          }
          .input, .inputLink {
            width: 100%;
            font-size:  $font-size-14;
            line-height: 15px;
            padding:12px;
            margin-bottom: 7px;
          }
          .textarea {
            width: 100%;
            height: 60px;
            margin-bottom: 10px;
            font-size:  $font-size-14;
            line-height: 15px;
          }
          .input[name=emailDesigner] {
            width: 70%;
          }
          .domain {
            font-size:  $font-size-14;
          }
        }
        .popup-btn-container {
          width: 100%;
          margin-top: 0px;
        }
      }
    }
  }
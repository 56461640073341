@import '../../styles/base';
.pdf-container {
    margin: 2% 10%;
    .pdf-heading {
      height: 30px;
      font-size: $font-size-16;
      cursor: pointer;
      .pdf-heading1 {
        font-weight: 600;
      }
      .logo-font {
        color:$color-primary;
        font-weight:600;
      }
    }
    .pdf-heading-desc {
      font-size:  $font-size-12;
      margin-bottom: 10px;
      .left {
        display: inline-block;
        width: 80%;
      }    
    }
    .pdf-result-heading {
      padding-top: 0px;
      font-weight: 600;
      font-size: $h3-font-size;
      line-height: 30px;
      text-align: center;
      color: $color-text-dark;
      margin-bottom: 30px;
    }
    .pdf-profile-data {
        width: 100%;
        margin-top: 25px;
        .left {
          margin-right: 2%;
        }
        .right {
          margin-left: 2%;
        }
        .pdf-profile {
          width: 48%;
          display: inline-block;
          vertical-align: top;
          .pdf-profile-heading {
            font-weight: 600;
            font-size:  $font-size-14;
            line-height: 24px;
            color: $color-text-dark;
            border-left: 2px solid  $color-text-dark;
            padding-left: 30px;
          }
          .pdf-profile-desc {
            margin-top: 20px;
            font-size:  $font-size-12;
            line-height: 18px;
            color: $color-text-dark;
            white-space: pre-line;
            padding-left: 30px;
          }
        }
      }
      .pdf-seperator {
        border-bottom: 1px solid #a0a0a0;
        transform: rotate(-0.1deg);
        margin: 15px 0px;
      }
    .pdf-section {
        .pdf-section-heading {
            font-weight: 600;
            font-size:  $font-size-16;
            line-height: 28px;
            color: $color-text-dark;
            display: inline-block;
        }
        .design-profile-image {
            width: 48%;
            display:inline-block;
        }
        .palette-div {
            width: 48%;
            display:inline-block;
            text-align: right;
            img {
                width: 75%;
            }
        }
      .pdf-row {
          margin-top: 10px;
          margin-bottom: 10px;
          .pdf-col {              
              margin-right: 10px;
              display: inline-block;
              vertical-align: top;
              .pdf-col-heading {
                font-size:  $font-size-10;
                line-height: 1.5;
                color: $color-text-dark;
                margin-bottom: 20px;
            }
            .pdf-row-desc {
                font-size: $font-size-14;
                line-height: 24px;
                color: $color-text-dark;
                margin-top: 12px;
                display:inline-block;
            }
            .pdf-row-heading1 {
                font-size: $font-size-14;
                line-height: 28px;
                color: $color-text-dark;
                display: inline-block;
            }
            .pdf-program-chart {
                margin-top: 10px;
                margin-left: 25%;
                height: 70px;
                width: 70px;
            }
            .pdf-chart-label {
                width: 100%;
                .label{
                    font-size: $font-size-10;
                    line-height: 20px;
                    color: $color-text-dark;
                    div {
                        display: inline-block;
                        vertical-align: bottom;
                        margin-right: 5px;
                    }
                    .ws-color-code {
                        width: 10px;
                        height: 10px;
                        border-radius: 5px;
                        background-color: $color-primary;
                        vertical-align: middle;
                    }
                    .of-color-code {
                        width: 10px;
                        height: 10px;
                        border-radius: 5px;
                        background-color: $graph-secondary-color;
                        vertical-align: middle;
                    }
                    .right{
                        float:right;
                    }
                }
            }
            .number {
                font-weight: 600;
                font-size:  $font-size-16;
                line-height: 24px;
                color: $color-text-dark;
                margin-top: 25px;
                margin-bottom: 10px;
                div {
                    width: 40%;
                    display: inline-block;
                }
                .colon {
                    width: 10%;                
                }
            }
            .desc {
                font-size: $font-size-10;
                line-height: 1.5;
                color: $color-text-dark;                        
                div {
                    width: 40%;
                    display: inline-block;
                }
                .right {               
                    float: right;
                }
            } 
          }
          .box-bg {
            margin-top: 20px;
            padding: 10px;
            background-color: #FBFBFB;
            height: 160px;
            width: 120px;
            border: 1px solid #E0EDE9;
          }
          .w100px {
            width: 100px !important;
          }
          .mRig0px {
            margin-right: 0px;
          }
        .pdf-space-group-div {
          background-color: #FBFBFB;
          border: 1px solid #E0EDE9;
          margin: 5% 0% 10% 0%;        
          .pdf-space-group-heading-div {
            width: 18%;
            text-align: center;
            display: inline-block;
            vertical-align: top;
            margin-top: 2%;
            .pdf-space-group-heading {
              font-weight: 600;
              font-size:  $font-size-14;
              line-height: 28px;
              color: $color-text-dark;;
            }
          }
          .pdf-space-group-data-div {
            width: 80%;
            margin-left: 2%;
            display: inline-block;
            .pdf-space-group-item {
                width: 100%;
                padding: 10px 0px;
                border-bottom: 1px solid #D7D7D7;
                font-size:  $font-size-10;
                line-height: 20px;
                div {
                    display: inline-block;                    
                }
                .w30per {
                    width: 30%;
                }
                .w20per {
                    width: 20%;
                    text-align: center;
                }
                .w10per {
                    width: 10%;
                    text-align: center;
                }
            }
          }
        }
      }
      .disclaimer {
        width: 100%;
        display:block;
        font-size:  $font-size-12;
        line-height: 20px;
        color: $color-text-dark;
        text-align: left;
        margin-bottom: 20px;
        margin-top: -40px;
        span {
          font-weight: 600;
          margin-right: 10px;
        }      
      }
      .pdf-cost-estimate {
          .grid-item {
              font-weight: normal;
              font-size:  $font-size-12;
              line-height: 24px; 
              text-align: right;
              margin-left: 0px;
              width: 100%;
              .cost-estimate-grid {
                .grid-item-col {
                  line-height: 2;
                  border-top: 1px solid #ECF5F1;
                  border-bottom: 1px solid #ECF5F1;
                  .col-item {
                    padding: 5px;
                    font-size:  $font-size-12;
                  }
                  .col-item:last-child {
                    text-align: center;
                  }
                }
              }
            }
            .description-text {
              font-size: $font-size-14;
              line-height: 20px;
              color: $color-text-dark;
              text-align: left;
              margin-top: 20px;
              margin-left: 0px;
              margin-bottom: 20px;
            }
            .repayment-notes {
              font-size: $font-size-14;
              line-height: 22px;
              color: $color-text-dark;
              text-align: left;
              margin-top: 25px;
              span {
                margin-right: 10px;
                font-weight: 600;
              }
            }
            .disclaimer {
                width: 100%;
                display:block;
                font-size: $font-size-12;
                line-height: 20px;
                color: $color-text-dark;
                text-align: left;
                margin-bottom: 20px;
                margin-top: 30px;
                span {
                  font-weight: 600;
                  margin-right: 10px;
                }      
              }
        }
    }
    .ptop72px {
      padding-top: 72px;
    }
    .mtop300px {
      margin-top: 120px;
    }
  }

.pdf-footer {
    width: 100%;
    height: auto;
    background-color: #d6e3df;
    bottom: 0px;
    .pdf-footer-row {
      padding: 20px 50px;
      display: flex;
      flex-wrap: wrap;
      .pdf-footer-col {
        flex-grow: 1;
        .sub-heading {
            font-size: $font-size-14;
            line-height: 18px;
            color: #434a48;
            margin-bottom: 10px;
          }
          a {
            font-weight: normal;
            font-size:  $font-size-14;
            line-height: 18px;
            color: $color-primary;
            text-decoration: none;
            display: block;
            margin-bottom: 10px;
          }
      }      
    }
  }

  .lang_en-US {
    .pdf-container {
      .pdf-seperator{
        margin: 35px 0;
      }
    }
  }

  .lang_es-ES {
    .pdf-container {
      .pdf-section {
        .pdf-row {
          .box-bg {
            .pdf-chart-label {
              .label {
                font-size: 0.5rem;
              }
            }
          }
        }
      }
    }
  }

  .lang_en-SG {
    .pdf-container {
      margin: 5% 10%;
      .pdf-heading-desc {
        margin-top: 10px;
      }
      .pdf-result-heading {
        margin-top: 35px;
      }
      .pdf-section {
        .design-profile-image {
          margin-bottom: 25px;
        }
        .palette-div {
          margin-bottom: 25px;
        }
        .pdf-cost-estimate {
          .grid-item {
            .cost-estimate-grid {
              margin-top: 25px;
              .header-1 {
                text-align: left;
                font-weight: 600;
                font-size: $font-size-16;
                line-height: 2;
                color: $color-text-dark;
                span {
                  margin-left: 5px;
                }
              }
              .grid-item-col {
                .col-item {
                  padding: 4px;
                  font-size: $font-size-12;
                }
              }
            }
          }
        }
      }
    }
  }
  .lang_en-UK {
    .pdf-container {
      .pdf-seperator{
        margin: 30px 0;
      }
    }
  }
  .lang_fr-FR .pdf-container .pdf-section .pdf-row {
    .pdf-col {
      .desc div {
        word-break: break-word;
      }
      .pdf-chart-label .label {
        font-size: $font-size-10;
      }
    }
    
    .box-bg {
      height: 170px;
      width: 125px;
    }
    .w100px {
      width: 90px !important;
    }
  }  
  @media print { 
    @page { 
        margin-top: 0 !important; 
        margin-bottom: 0 !important; 
    } 
    body { 
        padding-top: 40px !important; 
        padding-bottom: 40px !important; 
    }
    .divNoBreak {
      break-inside: avoid;
    }
    .lang_en-UK{      
      .pdf-seperator {
        page-break-after: avoid;
      }
      .pagebreak{
        page-break-after: always;
        &+.mtop300px{
          margin-top: 0 !important;
        }
        .ptop72px{
          padding-top: 40px !important;
        } 
      }
    }
  }
@import '../../styles/base';
.da_wrapper {
  background-color: #f5f5f5;
  margin-top: 0px;
  .top_banner {
    text-align: center;
    padding-top: 84px;
    display: flex;
    flex-flow: column;
    height: inherit;
    .identity {
      height: 60%;
      box-sizing: border-box;
      color: $white;
      &:first-child {
        height: 40%;
      }
      h1 {
        font-size: $heading-3;
        font-weight: 400;
        line-height: 1.4;
        margin-bottom: 10px;
      }
      .country_name {
        color: #f0e2c0;
        font-size: $heading-3;
        font-weight: 400;
        line-height: 1.4;
        margin-top: 0;
      }
    }
    .page_title {
      font-size: $heading-2;
      color: $white;
      font-weight: 400;
      span {
        font-weight: 600;
      }
    }
  }
  .react-parallax img {
    min-width: 100%;
    height: auto;
  }

  .page_details {
    margin-top: 80px;
    display: flex;
    --bg-overlay-color: rgb(36, 36, 36);
    --fill-layer-image-opacity: 0.25;
    &.flex_end {
      justify-content: flex-end;
    }
    &.flex_start {
      justify-content: flex-start;
      flex-direction: row-reverse;
      .content_box{
        text-align: right;
      }
    }
    .content_box {     
      background-color: $white;
      max-height: 100%;
      box-sizing: border-box;
      h3 {
        font-size: $heading-3;
        font-weight: 400;
      }
      p {
        font-size: $h2-font-size;
        font-weight: 600;
        line-height: 1.4;
        color: $color-text-dark;
      }
    }
    .col_1 {
      width: 40%;
      padding: 150px 50px 0px 50px;
      box-sizing: border-box;
      img {
        max-width: 100%;
      }
    }
    .col_2 {
      width: 60%;
      max-width: 100%;
      padding: 150px 50px 100px;
      background: $white;
      img {
        width: 100%;
      }
    }
    .bg_animate {
      opacity: var(--fill-layer-image-opacity);
      img {
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
      }
    }
    .img_animate {
      --bg-overlay-color: $white;
      background-color: $white;
      img {
        max-width: 100%;
      }
    }
    &:last-child {
      padding-bottom: 50px;
    }
  }
}
.kit_of_parts {
  text-align: center;
  flex-direction: column !important;
  .content_box{
    width: 60% !important;
    margin: 0 auto !important;
    text-align: center !important;
    p{
      margin-top: 0;
    }
  }
  .col_1,
  .col_2 {
    width: 100% !important;
    box-sizing: border-box;
    padding-top: 100px !important;
  }
  .col_2 {
    img{
      width: 75% !important;
      margin-top: -50px;
    }
  }
}

.bg_fade{
  &:after{
    content: '';
    background: rgba($white, 50%);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .react-parallax-content {
    z-index: 9
  }
}
.mb_50 {
  margin-bottom: 50px !important;
}
.close_page {
  position: fixed;
  border-radius: 99em;
  width: 40px;
  height: 40px;
  box-shadow: 0 0 10px rgba($black, 50%);
  right: 10px;
  top: 70px;
  font-size: $h2-font-size;
  background: $color-primary;
  line-height: 42px;
  color: #ccc;
  z-index: 11;
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .da_wrapper {
    .top_banner {
      .page_title {
        font-size: $heading-2;
      }
      .identity{
        h1{
          font-size: $heading-5;
        }
        .country_name{
          font-size: $heading-6;
        }
      }
    }
    .page_details {
      margin-top: 50px;
      .col_1 {        
        box-sizing: border-box;
        padding: 100px 50px 0 50px;
      }
      .col_2 {        
        box-sizing: border-box;
        padding: 100px 50px 50px;
      }
      .content_box {
        h3 {
          font-size: $heading-6;
        }
        p {
          font-size: $h3-font-size;
          font-weight: 400;
        }
      }
    }
    .kit_of_parts {
      text-align: center;
      flex-direction: column !important;
      .col_1,
      .col_2 {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .close_page {
    display: block !important;
  }
  .da_wrapper {
    margin-top: 0px;
    .top_banner {
      .page_title {
        font-size: $heading-3;
      }
      .identity{
        h1{
          font-size: $heading-6;
        }
        .country_name{
          font-size: $h1-font-size;
        }
      }
    }
    .page_details {
      flex-direction: column !important;
      margin-top: 50px;
      .col_1 {
        padding: 50px 30px 0px;
        width: 100%;
        box-sizing: border-box;
      }
      .col_2 {
        padding: 30px;
        width: 100%;
        box-sizing: border-box;
      }
      .content_box {
        h3 {
          font-size: $heading-6;
          text-align: left;
        }
        p {
          font-size: $font-size-16;
          text-align: left;
          font-weight: 400;
        }
      }
    }
    .kit_of_parts {
      text-align: center;
      flex-direction: column !important;
      .content_box{
        width: 100% !important;        
      }
      .col_1{width: 100% !important; padding-top: 50px !important;}
      .col_2 {
        width: 100% !important;
        padding-top: 70px !important;
        img {
          width: 100% !important;
        }
      }
    }
  }
}
body.modalOpen[data-aos-easing="ease"]::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body.modalOpen[data-aos-easing="ease"] {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
